import React from 'react';
import './avatar-label.scss';
import { Text, Avatar } from '@ui-kit';
import { geShortCut } from '@shared';

const AvatarLabel = ({ 
  author = {}, 
  className = '', 
  showEmail = false, 
  text, 
  size,
  srcStatic, 
}) => {
  const classes = [
    'avatar-label__container',
    'd-flex',
    'ai-center',
    ...className.split(' '),
  ];

  return (
    <div className={classes.join(' ')}>
      <Avatar
        src={author.ava}
        srcStatic={srcStatic}
        size={size}
        text={geShortCut(author.firstName, author.lastName)}
      />
      <div className="author__wrapper">
        <Text as="h6" className="author__name">
          <Text as="span" className="mr-8">{author.firstName || ''}</Text>
          <Text as="span">{author.lastName || ''}</Text>
        </Text>
        {showEmail && (
          <Text as="h6" className="author__email">
            {author.email}
          </Text>
        )}
        {text && (
          <Text as="h6" className="author__text">
            {text}
          </Text>
        )}
      </div>
    </div>
  );
};

export default AvatarLabel;
