import { createSelector } from 'reselect';

export const getProducts = (state) => state.productsData;

export const getCurrentProducts = createSelector(
  getProducts,
  (productsData) => productsData.products
);

export const getIsLoading = createSelector(
  getProducts,
  (productsData) => productsData.isLoading
);

export const getAllAmount = createSelector(
  getProducts,
  (productsData) => productsData.entireAmount
);

export const getFreeAmount = createSelector(
  getProducts,
  (productsData) => productsData.freeAmount
);

export const getPaginationFreeAmount = createSelector(
  getProducts,
  (productsData) => productsData.paginationFreeAmount
)

export const getPublishedEntireAmount = createSelector(
  getProducts,
  (productsData) => productsData.publishedEntireAmount
);

export const getMonthlyAmount = createSelector(
  getProducts,
  (productsData) => productsData.monthlyAmount
);

export const getPaginationAmount = createSelector(
  getProducts,
  (productsData) => productsData.paginationAmount
);

export const getMsConnected = createSelector(
  getProducts,
  (productsData) => productsData.msConnected
);

export const getDashboardTotals = createSelector(
  getProducts,
  (productsData) => productsData.dashboardTotals
);

export const getDashboardMost = createSelector(
  getProducts,
  (productsData) => productsData.dashboardMost
);

export const getDashboardGraph = createSelector(
  getProducts,
  (productsData) => productsData.dashboardGraph
);

export const getHomeGallery = createSelector(
  getProducts,
  (productsData) => productsData.homeGallery
);

export const getSalesProducts = createSelector(
  getProducts,
  (productsData) => productsData.salesProducts
);
