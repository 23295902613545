import React from 'react';
import './payment-success.scss';

import { Text, Link, Icon, IconFeatured } from '@ui-kit';
import {
    ADMIN_PURCHASES_URL,
    ADMIN_USER_BILLS_URL,
  } from '@app/routes';
import DownloadIcon from '@ui-kit/icons/download-accent600.svg';
import FileIcon from '@ui-kit/icons/file-accent600.svg';


const PaymentSuccess = () => {

  return (
    <div className="payment-status__success">
      <div className="payment-status__header">
        <Text as="h5" className="sub__title mb-12">
          Payment was successful
        </Text>
        <Text as="h1" className="title mb-24">
          Congratulations on a useful purchase! 
        </Text>
        <Text as="h3" className="sup__title">
            We appreciate your support. Each purchase is of great importance for us. Hope you will come to Art4Free again.
        </Text>
      </div>
      <div className="payment-status__content">
        <div className="feature__content">
          <div className="feature__item">
            <div className="icon">
              <IconFeatured src={DownloadIcon} theme="primary" size="lg"/>
            </div>
            <div  className="feature__item-content">
                <Text as="h3" className="title">
                  Download illustrations
                </Text>
                <Text as="h5" className="description">
                  The illustrations you purchased are already in your personal 
                  account! We carefully store all your purchases here. There you can 
                  download the license agreement.
                </Text>
                <Link
                  to={ADMIN_PURCHASES_URL.pathname}
                  theme="link-color"
                  className="arrow-right"
                >
                  Go to purchases
                </Link>
            </div>
          </div>
          <div className="feature__item">
            <div className="icon">
              <IconFeatured src={FileIcon} theme="primary" size="lg"/>
            </div>
            <div  className="feature__item-content">
                <Text as="h3" className="title">
                  Payment receipt
                </Text>
                <Text as="h5" className="description">
                  To download a paid receipt in the format you need, follow the 
                  below link. For your convenience, we have also sent a payment 
                  receipt to your email.
                </Text>
                <Link
                  to={ADMIN_USER_BILLS_URL.pathname}
                  theme="link-color"
                  className="arrow-right"
                >
                  Go to receipt
                </Link>
            </div>
          </div>
        </div>
        <div className="image-container">
          <div className="image-background"></div>
          <Icon src="/images/success.png" />
        </div>
      </div>
    </div>
  )
}

export default PaymentSuccess;