import React from "react";
import "./empty-products.scss";

import { Text, Icon, Link, Tooltip } from "@ui-kit";
import CheckIcon from "@ui-kit/icons/check-accent500-md.svg";
import QuestionIcon from '@ui-kit/icons/help-circle-gray400.svg';

const EmptyProducts = () => {
    const ADD_PRODUCT_URL = process.env.REACT_APP_INSTRUCTION_URL;
    const helpContent = (
      <>
        <Text as="h6" className="tolltipe__title">
          Get Product Instructions
        </Text>
        <Text as="h6" className="tolltipe__subtitle">
          Download our step-by-step guide on<br/>
          how to upload your work to our server
        </Text>
      </>
    );

    return (
            <div className="products__empty">
              <div className="products__header pr">
                <Text as="h5" className="title">
                  There are no products yet, but...
                </Text>
                <Text as="h2" className="subtitle">
                  …you can add them right now!
                </Text>
    
                <div className="promote-empty__content">
                  <div className="list-content d-flex">
                    <Icon src={CheckIcon} />
                    <Text as="h5">Upload your illustrations to our server</Text>
                  </div>
                  <div className="list-content d-flex">
                    <Icon src={CheckIcon} />
                    <Text as="h5">
                      Set up your products - you can add or change the title,
                      description and tags
                    </Text>
                  </div>
                  <div className="list-content d-flex">
                    <Icon src={CheckIcon} />
                    <Text as="h5">
                      Publish your products to our gallery to receive 80% of every
                      sale of your products
                    </Text>
                  </div>
                </div>
    
                <div className="d-flex pr">
                  <Link
                    to={ADD_PRODUCT_URL}
                    theme="secondary"
                    size="md"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Add product
                  </Link>
                  <Tooltip position="right" content={helpContent}>
                    <Icon src={QuestionIcon} className="ml-6" />
                  </Tooltip>
                </div>
              </div>
              <div className="banner">
                <Icon src="/images/banner-products.png" />
              </div>
            </div>
          );
};

export default EmptyProducts;