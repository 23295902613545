import React from "react";
import "../options.scss";

import Check from "@ui-kit/icons/check-accent500-md.svg";
import Chevron from "@ui-kit/icons/chevron-accent700.svg";
import { Text, Icon, Badge, Switcher, Link } from "@ui-kit";
import { BROWSE_URL } from '@app/routes';

const Free = () => {
  return (
    <div className="options__item">
      <Text as="h4" className="title mb-6">
        Free Illustration
      </Text>
      <Badge text="$0" size="md" theme="gray" className="mb-6" />
      <Text as="h5" className="text mb-16">
        Get high-quality illustrations for free!
      </Text>

      <Text as="h4" className="subtitle mb-6">
        Commercial license:
      </Text>
      <div className="switch d-flex mb-16">
        <Switcher
          condition={true}
          theme="dark"
          onSwitcherClick={() => {}}
          className="mr-8"
          size="sm"
        />
        <Text as="h6" className="switch-text active">
          Extended
        </Text>
      </div>

      <Text as="h4" className="subtitle mb-10">
        What you get:
      </Text>
      <div className="options__get">
        <div className="get-item">
          <Icon src={Check} className="get__ico" alt="check" />
          <Text as="h5" className="text">
            Free illustrations without watermarks
          </Text>
        </div>
        <div className="get-item">
          <Icon src={Check} className="get__ico" alt="check" />
          <Text as="h5" className="text">
            High resolution for any purpose
          </Text>
        </div>
      </div>

      <div className="d-flex">
        <Text as="h4" className="subtitle mb-6">
          How it works
        </Text>
        <Icon src={Chevron} className="how-works__ico" alt="chevron" />
      </div>
      <ul className="options__how-works">
        <li className="text">
          <Text as="h5">Choose an illustration from our gallery</Text>
        </li>
        <li className="text">
          <Text as="h5">Download the image without restrictions</Text>
        </li>
      </ul>

      <Link to={BROWSE_URL}>Get it for free</Link>
    </div>
  );
};

export default Free;
