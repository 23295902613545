import React, {useState} from 'react';
import { Select } from 'antd';
import './dropdown.scss';

import { Text, Icon } from '@ui-kit';
import CheckIcon from '@ui-kit/icons/check-def-accent600-md.svg'
import ArrowFlex from '@ui-kit/icons/chevron-gray500.svg';
import SearchIcon from "@ui-kit/icons/search-gray500.svg";

const Dropdown = (props) => {
  const {
    id = new Date(),
    mode = '',
    data = [],
    size= "large",
    value = '',
    search = false,
    placeholder = '',
    className = '',
    iconLeft = false,
    iconRight = false,
    isDisabled = false,
    renderOption = () => {},
    onChooseCountry = () => {},
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [filteredData, setFilteredData] = useState(data);

  const containerClasses = ['antd-dropdown__container', ...className];

  if (isDisabled) {
    containerClasses.push('disabled');
  }

  if (iconLeft) {
    containerClasses.push('icon__left');
  }

  if (iconRight || search) {
    containerClasses.push('icon__right');
  }

  const onChange = (value) => {
    onChooseCountry({code: value});
    setFilteredData(data);
  };

  const onSearch = (value) => {
    if (!value) {
      return setFilteredData(data);
    };
    
    const filteredList = data.filter((item) => item?.name.toLowerCase().startsWith(value.toLowerCase()));
    filteredList.sort((a, b) => a.name[0].localeCompare(b.name[0]));

    setFilteredData(filteredList);
  };

  const onOpen = () => {
    setIsOpen(!isOpen);
  }

  const renderItem = (item, index) => {
    if(renderOption) {
        return (
            <Select.Option 
                value={item.value} 
                label={item.name} 
                key={`option-${index}`}
            >
                {renderOption(item, index)}
            </Select.Option>
        );
    };

    return (
        <Select.Option 
            value={item.value} 
            label={item.name}
            key={`option-${index}`}
        >
            <Text as="h5">{item.name}</Text>
        </Select.Option>
    );
  };

  const renderIconSearch = () => {
    if (isOpen && search) {
        return <Icon src={SearchIcon} className="search" />
    };

    if (isOpen) {
        return <Icon src={ArrowFlex} className="rotate" />
    };

    return <Icon src={ArrowFlex} />
  };

  const selectOptions = {};

  if (search) {
    selectOptions['onSearch'] = onSearch;
  }

  return (
    <div className={containerClasses.join(' ')} id={id}>
        <Select  {...selectOptions} 
          size={size}
          mode={mode}
          showSearch={search}
          value={value}
          placeholder={placeholder}
          menuItemSelectedIcon={<Icon src={CheckIcon} />}
          suffixIcon={renderIconSearch()}
          optionFilterProp="label"
          onChange={onChange}
          onDropdownVisibleChange={onOpen}
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          disabled={isDisabled}
        >
            {filteredData.map((item, index) => renderItem(item, index))}
        </Select>
    </div>
  );
};

export default Dropdown;

/*
 <Dropdown
        data={PRODUCT_DROPDOWN_LIST}
        mode="multiple"
        search={true}
        placeholder= "Sort by"
        value={sortBy}
        id="products-dropdown"
        renderOption={renderDropDowns}
      />
*/
