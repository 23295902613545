import React, { useState, useEffect } from "react";
import "../options.scss";

import Check from "@ui-kit/icons/check-accent500-md.svg";
import Chevron from "@ui-kit/icons/chevron-accent700.svg";
import { Text, Icon, Badge, Switcher, Link } from "@ui-kit";
import { ADMIN_PROJECT_CREATE_URL } from "@app/routes";

const Custom = () => {
  const [switchCustom, setSwitchCustom] = useState(false);

  const switchLicense = (value) => {
    setSwitchCustom(value);
  };

  useEffect(() => {
    renderItem();
    // eslint-disable-next-line
  }, [switchCustom]);

  const renderItem = () => {
    if (!switchCustom) {
      return (
        <div className="options__item">
          <Text as="h4" className="title mb-6">
            Custom illustration
          </Text>
          <Badge text="$23 / hour" size="md" theme="gray" className="mb-6" />
          <Text as="h5" className="text mb-10">
            Get a unique, tailor-made illustration that meets your exact needs!
          </Text>
          <Text as="h4" className="subtitle mb-10">
            Commercial license:
          </Text>
          <div className="switch d-flex mb-16">
            <Text as="h6" className="switch-text mr-8 active">
              Extended
            </Text>
            <Switcher
              condition={switchCustom}
              theme="dark"
              onSwitcherClick={(value) => {
                switchLicense(value);
              }}
              size="sm"
              className="mr-8 check"
            />
            <Text as="h6" className="switch-text">
              Exclusive
            </Text>
          </div>

          <Text as="h4" className="subtitle mb-10">
            What you get:
          </Text>
          <div className="options__get">
            <div className="get-item">
              <Icon src={Check} className="get__ico" alt="check" />
              <Text as="h5" className="text">
                Personalized illustration
              </Text>
            </div>
            <div className="get-item">
              <Icon src={Check} className="get__ico" alt="check" />
              <Text as="h5" className="text">
                Affordable solution for marketing, advertising, and design
              </Text>
            </div>
          </div>

          <div className="d-flex">
            <Text as="h4" className="subtitle mb-6">
              How it works
            </Text>
            <Icon src={Chevron} className="how-works__ico" alt="chevron" />
          </div>
          <ul className="options__how-works">
            <li className="text">
              <Text as="h5">Fill out the brief</Text>
            </li>
            <li className="text">
              <Text as="h5">Get a time estimate</Text>
            </li>
            <li className="text">
              <Text as="h5">
                We'll start working to bring your idea to life
              </Text>
            </li>
          </ul>

          <Link to={ADMIN_PROJECT_CREATE_URL}>Create an illustration</Link>
        </div>
      );
    }

    return (
      <div className="options__item">
        <Text as="h4" className="title mb-6">
          Custom illustration
        </Text>
        <Badge text="$80 / hour" size="md" theme="gray" className="mb-6" />
        <Text as="h5" className="text mb-10">
          Get a unique, tailor-made illustration with full copyright — the
          illustration becomes yours alone!
        </Text>
        <Text as="h4" className="subtitle mb-10">
          Commercial license:
        </Text>
        <div className="switch d-flex mb-16">
          <Text as="h6" className="switch-text mr-8">
            Extended
          </Text>
          <Switcher
            condition={switchCustom}
            theme="dark"
            onSwitcherClick={(value) => {
              switchLicense(value);
            }}
            size="sm"
            className="mr-8"
          />
          <Text as="h6" className="switch-text active">
            Exclusive
          </Text>
        </div>

        <Text as="h4" className="subtitle mb-10">
          What you get:
        </Text>
        <div className="options__get">
          <div className="get-item">
            <Icon src={Check} className="get__ico" alt="check" />
            <Text as="h5" className="text">
              Personalized illustration
            </Text>
          </div>
          <div className="get-item">
            <Icon src={Check} className="get__ico" alt="check" />
            <Text as="h5" className="text">
              Full exclusive rights
            </Text>
          </div>
          <div className="get-item">
            <Icon src={Check} className="get__ico" alt="check" />
            <Text as="h5" className="text">
              Without restrictions — edit, sell, and use in any projects
            </Text>
          </div>
        </div>

        <div className="d-flex">
          <Text as="h4" className="subtitle mb-6">
            How it works
          </Text>
          <Icon src={Chevron} className="how-works__ico" alt="chevron" />
        </div>
        <ul className="options__how-works">
          <li className="text">
            <Text as="h5">Fill out the brief</Text>
          </li>
          <li className="text">
            <Text as="h5">Get a time estimate</Text>
          </li>
          <li className="text">
            <Text as="h5">We'll start working to bring your idea to life</Text>
          </li>
        </ul>

        <Link to={ADMIN_PROJECT_CREATE_URL}>Create an illustration</Link>
      </div>
    );
  };

  return <>{renderItem()}</>;
};

export default Custom;
