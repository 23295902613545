import settingsService from '../api';
import { 
  SET_SETTINGS,
  SET_SETTINGS_LOADING,
  SET_BLOG,
  SET_BLOG_ITEM,
  SET_PAGINATION_AMOUNT,
} from './settings.constants';


export const getSettingsTC = () => {
  return async (dispatch) => {
    try {
      const resp = await settingsService.getSettings();

      if (!resp?.data) {
        return;
      }

      dispatch(setSettingsAC(resp.data));
    } catch (error) {
      console.log(error);
    }
  };
};

export const getBlogTC = (params) => {
  return async (dispatch) => {
    try {
      dispatch(setSettingsLoadingAC(true))
      const resp = await settingsService.getBlog(params);

      if (!resp?.data) {
        return;
      }

      const {count, rows} = resp.data;
      dispatch(setBlogAC(rows));
      dispatch(setPaginationAmountAC(count));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setSettingsLoadingAC(false))
    }
  };
};

export const getBlogPostTC = (id) => {
  return async (dispatch) => {
    try {
      dispatch(setSettingsLoadingAC(true))
      const resp = await settingsService.getBlogPost(id);

      if (!resp?.data) {
        return;
      }

      dispatch(setBlogPostAC(resp.data));
    } catch (error) {
      console.log(error);
    }finally {
      dispatch(setSettingsLoadingAC(false))
    }
  };
};

//ACTIONS
export const setSettingsAC = (payload) => {
  return {
    type: SET_SETTINGS,
    payload,
  };
};

export const setSettingsLoadingAC = (payload) => {
  return {
    type: SET_SETTINGS_LOADING,
    payload,
  };
};

export const setBlogAC = (payload) => {
  return {
    type: SET_BLOG,
    payload,
  };
};

export const setBlogPostAC = (payload) => {
  return {
    type: SET_BLOG_ITEM,
    payload,
  };
};

export const setPaginationAmountAC = (amount) => {
  return {
    type: SET_PAGINATION_AMOUNT,
    payload: amount,
  };
};
