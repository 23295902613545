import React, {useState, useEffect} from 'react';
import { Popover } from 'antd';
import './table-products.scss';

import { Icon, Text, CheckBox } from '@ui-kit';
import { ModalImage } from '@features';
import ItemActions from './itemActions';
import { ITEM_ACTIONS_LIST } from './item-actions.consts';
import MoreIcon from '@ui-kit/icons/more-vertical-gray400.svg';


const ProductsItem = ({ item = {}, checkedItems = [], setCheckedItems = ()=>{}, onChange = ()=>{} }) => {
  const [itemsList, setItemsList] = useState(ITEM_ACTIONS_LIST);
  const [isItemCheck, setItemCheck] = useState();

  const { 
    pathToBrowse = '', 
    pathToPreview = '',
    title = '',
    id = 0,
    wentPublicAt = '-',
    createdAt = '-',
    rate_type = 'Extended commercial',
    amount_views = 0,
    //amount_likes = 0,
    //amount_downloaded = 0,
    amount_earned = 0,
    //price = 2,
    isPublic = 1,
    isPromoted = 0,
    isFree = 0,
  } = item;

  const uploadDate = createdAt ? new Date(createdAt).toLocaleString(undefined, {
    year: 'numeric', 
    month: '2-digit', 
    day: '2-digit'
  }) : '-';
  const uploadTime = createdAt ? new Date(createdAt).toLocaleTimeString(undefined, {
    hour: 'numeric', 
    minute: 'numeric',
    second: 'numeric',
  }) : '-';

  const publicDate = wentPublicAt ? new Date(wentPublicAt).toLocaleString(undefined, {
    year: 'numeric', 
    month: '2-digit', 
    day: '2-digit'
  }) : '-';
  const publicTime = wentPublicAt ? new Date(wentPublicAt).toLocaleTimeString(undefined, {
    hour: 'numeric', 
    minute: 'numeric',
    second: 'numeric',
  }) : '-';

  const PRODUCT_URL = process.env.REACT_APP_PRODUCTS_URL;
  const isChecked = checkedItems.filter((item) => item.id === id);
  // const earned = (amount_downloaded * price) + '$';

  useEffect(() => {
    setItemCheck(!!isChecked.length);
    // eslint-disable-next-line
  }, [checkedItems]);

  useEffect(() => {
    let list = [];
    if (isPublic) {
      list = itemsList.filter((item) => item?.component?.props?.id !== 'activate')
    } else {
      list = itemsList.filter((item) => item?.component?.props?.id !== 'hide')
    };

    if (isPromoted) {
      list = list.filter((item) => item?.component?.props?.id !== 'promote')
    } else {
      list = list.filter((item) => item?.component?.props?.id !== 'notPromote')
    };

    if (isFree) {
      list = list.filter((item) => item?.component?.props?.id !== 'free')
    } else {
      list = list.filter((item) => item?.component?.props?.id !== 'notFree')
    };

    setItemsList(list);
    // eslint-disable-next-line
  }, [isPublic, isPromoted, isFree]);

  const setCheck = (event) => {
    const value = event.target.checked;
    setItemCheck(value);
    
    if(value) {
      setCheckedItems([...checkedItems, item])
    } else {
      const newChecked = checkedItems.filter((item) => item.id !== id)
      setCheckedItems(newChecked)
    };
  };

  const onSelect = (action) => {
    onChange(action, id);
  }

  const renderStatus = () => {
    const statusList = []
    if (+isPromoted) statusList.push('Promoted');
    if (+isFree) statusList.push('Free');
    if (!statusList.length || !+isFree) statusList.push(rate_type || 'Extended commercial');

    return statusList.join(', ');
  }

  const content = (
    <Text as="h5">{title || ''}</Text>
  );

  return (
    <div className="admin__table-row">
      <div className="product-name d-flex ai-center">
        <CheckBox
          className="checkbox__item"
          checked={isItemCheck}
          onChange={setCheck}
        />
        <ModalImage
          key={`img-${id}`}
          src={`${PRODUCT_URL}/${pathToBrowse}`}
          backgroundSrc={`${PRODUCT_URL}/${pathToPreview}`}
        />

        <div className="product-title">
          <Popover
            overlayStyle={{overflow: 'initial'}}
            overlayClassName="product-title__pop"
            content={content}
            trigger={'hover'}
            arrow={true}
            placement={'topLeft'}
          >
            <>
              <Text as="h5">{title || ''}</Text>
            </>
          </Popover>
            <Text as="h6">#{id}</Text>
        </div>
      </div>

      <div className="product-date">
          <Text as="h5">{publicDate || ''}</Text>
          <Text as="h6">{publicTime || ''}</Text>
      </div>

      <div className="product-date">
          <Text as="h5">{uploadDate || ''}</Text>
          <Text as="h6">{uploadTime || ''}</Text>
      </div>

      {/*<div className="product-favorites">
          <Text as="h6">{amount_likes || '0'}</Text>
      </div>*/}

      <div className="product-status">
          <Text as="h6">{renderStatus()}</Text>
      </div>

      <div className="product-views">
          <Text as="h6">{amount_views || '0'}</Text>
      </div>

      <div className="product-earned">
          <Text as="h6">{amount_earned}</Text>
      </div>

      <div className="product-item__menu">
        <ItemActions 
          actionsList={itemsList}
          title={<Icon src={MoreIcon} />} 
          onSelect={onSelect}
          className="product-actions"
        />
      </div>

    </div>
  );
};

export default ProductsItem;
