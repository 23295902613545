import React, { useState, useContext, useEffect } from "react";
import "./horizontalTabsAdmin.scss";
import { useLocation, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "antd";
import dayjs from "dayjs";

import { replaceTo } from "@shared";
import { Button, Link, Filters, FiltersSelect } from "@ui-kit";
import { SearchFormBg } from "@features";
import {
  ADMIN_PROJECTS_URL,
  ADMIN_USERS_URL,
  ADMIN_DASHBOARD_URL,
  //ADMIN_PRODUCTS_URL,
  //ADMIN_MANAGEMENT_SETTINGS_URL,
  ADMIN_ARCHIVE_PROJECTS_URL,
} from "@app/routes";

import { ROLES } from "@entities/user";
import {
  getUserRole,
  getArchiveProjectsTC,
  getProjectsTC,
  getUsersTC,
  getSettingsTC,
  getReadyProductsTC,
  getSalesProductsTC,
  getSearchPage,
  setProjectsSearchValueAC,
} from "@entities";
import RefreshIcon from "@ui-kit/icons/refresh-cw-gray500.svg";
//import CalendarIcon from "@ui-kit/icons/calendar-gray500.svg";
import { ProjectsContext, UsersContext, ProductsContext } from "@shared/hooks";

const HorizontalTabsAdmin = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { RangePicker } = DatePicker;
  dayjs.locale(navigator.language);

  const projectsContext = useContext(ProjectsContext);
  const usersContext = useContext(UsersContext);
  const productsContext = useContext(ProductsContext);
  const role = useSelector(getUserRole);
  const searchPage = useSelector(getSearchPage);

  const [searchValue, setSearchValue] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [dateRange, setDateRange] = useState([]);

  const today = new Date();
  const MIN_DATE = +process.env.REACT_APP_MIN_DATE_PICKER;
  const followingData = productsContext?.followingData || {};

  const isAdmin = role === ROLES.ADMIN;
  let actions = "";
  let filtersList = [];
  let filterOrder = "";
  const visibleOrder = "isPublic";
  const visibleList = [
    { name: "Active", value: "1" },
    { name: "Hidden", value: "0" },
  ];
  const projectsList = [
    {
      title: "Select a state",
      order: "state",
      list: [
        { name: "Brief", value: "1" },
        { name: "Sketching", value: "3" },
        { name: "Illustrating", value: "5" },
        { name: "Completed", value: "8" },
      ],
    },
    {
      title: "Select a status",
      order: "status",
      list: [
        { name: "Sketch is ready", value: "sketchReady" },
        { name: "Illustration is ready", value: "illustrationReady" },
        { name: "Waiting for a response", value: "waitingResponse" },
        // { name: 'Switching to exclusive license', value: 'switchingToExclusive' },
        { name: "Work in progress", value: "workProgress" },
        { name: "Source files are ready", value: "sourceReady" },
      ],
    },
  ];

  useEffect(() => {
    const search = searchParams.get("search") || searchParams.get("searchBy");
    if (search && !searchValue) {
      setSearchValue(search);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!today) return;

    getPeriodRange();

    // eslint-disable-next-line
  }, [followingData?.stamp]);

  const getPeriodRange = () => {
    if (followingData?.startDate) {
      const startDate = followingData?.startDate;
      const endDate = followingData?.endDate;

      return setDateRange([dayjs(startDate), dayjs(endDate)]);
    }

    const startDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    const endDate = today;

    return setDateRange([dayjs(startDate), dayjs(endDate)]);
  };

  const resetFilter = (filterOrder) => {
    searchParams.delete(filterOrder);
    setSearchParams(searchParams, { replace: true });

    onUpdateData();
  };

  const searchPlaceholder = () => {
    switch (searchPage) {
      case "projects": {
        filtersList = projectsList;
        return "Search by projects";
      }

      case "archive": {
        filtersList = projectsList;
        return "Search by archive projects";
      }

      case "users": {
        filterOrder = "search";
        filtersList = [
          { name: "Users", value: "user" },
          { name: "Executors", value: "executor" },
          { name: "Admins", value: "admin" },
        ];
        actions = (
          <div className="filter-actions__line">
            <Button
              size="sm"
              theme="link-gray"
              onClick={() => resetFilter(filterOrder)}
            >
              Reset
            </Button>
          </div>
        );
        return "Search by users";
      }

      case "mySales": {
        filtersList = [
          {
            title: "Select a field",
            order: "searchColumns",
            list: [
              { name: "Title", value: "title" },
              { name: "Product Id", value: "productId" },
              { name: "Customer name", value: "customerName" },
              { name: "Customer email", value: "customerEmail" },
            ],
          },
        ];
        return "Search by sales";
      }

      case "products": {
        filtersList = [
          {
            title: "Select a field",
            order: "searchColumns",
            list: [
              { name: "Title", value: "title" },
              { name: "Product Id", value: "id" },
              { name: "Description", value: "html_description" },
              { name: "Tag", value: "key_words" },
            ],
          },
        ];
        return "Search by products";
      }

      case "promote": {
        filterOrder = "fieldType";
        filtersList = [
          { name: "Newest", value: "createdAt" },
          { name: "Popular", value: "popular" },
        ];
        return "Search by promo products";
      }

      case "free": {
        filterOrder = "fieldType";
        filtersList = [
          { name: "Newest", value: "createdAt" },
          { name: "Popular", value: "popular" },
        ];
        return "Search by free products";
      }

      default:
        return "Search by";
    }
  };

  const onSubmitSearch = (e) => {
    e.preventDefault();
  };

  const onChangeSearch = async (event) => {
    const value = event?.target?.value || "";
    let normaliseValue = replaceTo(value, `[?!)(:;",'}{]`, "");

    setSearchValue(normaliseValue);

    switch (searchPage) {
      case "archive": {
        dispatch(setProjectsSearchValueAC(normaliseValue));
        await projectsContext.onProjectsSearch(normaliseValue);
        break;
      }

      case "projects": {
        dispatch(setProjectsSearchValueAC(normaliseValue));
        await projectsContext.onProjectsSearch(normaliseValue);
        break;
      }

      case "users": {
        await usersContext.onUsersSearch(normaliseValue);
        break;
      }

      case "mySales": {
        await productsContext.onProductsSearch(normaliseValue);
        break;
      }

      case "products": {
        await productsContext.onProductsSearch(normaliseValue);
        break;
      }

      case "promote": {
        await productsContext.onProductsSearch(normaliseValue);
        break;
      }

      case "free": {
        await productsContext.onProductsSearch(normaliseValue);
        break;
      }

      default:
        break;
    }

    event.preventDefault();
  };

  const isActiveButton = (url) => {
    const path = url.pathname;

    if (path === location.pathname) {
      return "active";
    }

    return "";
  };

  const onUpdateData = () => {
    setSearchValue("");

    switch (searchPage) {
      case "archive": {
        dispatch(getArchiveProjectsTC({ limit: 30, page: 1 }));
        break;
      }

      case "projects": {
        dispatch(getProjectsTC({ limit: 30, page: 1 }));
        break;
      }

      case "users": {
        dispatch(getUsersTC({ limit: 30, page: 1 }));
        break;
      }

      case "mySales": {
        setSearchParams({ limit: 80, page: 1 }, { replace: true });
        dispatch(getSalesProductsTC({ limit: 80, page: 1 }));
        break;
      }

      case "products": {
        setSearchParams({ limit: 80, page: 1, isPublic: 1 }, { replace: true });
        dispatch(getReadyProductsTC({ limit: 80, page: 1, isPublic: 1 }));
        break;
      }

      case "promote": {
        setSearchParams(
          { limit: 80, page: 1, isPromote: 1, isFree: 0 },
          { replace: true }
        );
        dispatch(
          getReadyProductsTC({ limit: 30, page: 1, isPromote: 1, isFree: 0 })
        );
        break;
      }

      case "free": {
        setSearchParams(
          { limit: 80, page: 1, isPromote: 0, isFree: 1 },
          { replace: true }
        );
        dispatch(
          getReadyProductsTC({ limit: 30, page: 1, isPromote: 0, isFree: 1 })
        );
        break;
      }

      case "settings": {
        dispatch(getSettingsTC());
        break;
      }

      default:
        break;
    }
  };

  const onChangeFilter = () => {
    const search = window.location.search;
    switch (searchPage) {
      case "projects": {
        dispatch(getProjectsTC(search));
        break;
      }

      case "archive": {
        dispatch(getArchiveProjectsTC(search));
        break;
      }

      case "users": {
        dispatch(getUsersTC(search));
        break;
      }

      case "mySales": {
        dispatch(getSalesProductsTC(search));
        break;
      }

      case "products": {
        dispatch(getReadyProductsTC(search));
        break;
      }

      case "promote": {
        dispatch(getReadyProductsTC(search));
        break;
      }

      case "free": {
        dispatch(getReadyProductsTC(search));
        break;
      }

      case "settings": {
        dispatch(getSettingsTC());
        break;
      }

      default:
        break;
    }
  };

  const renderFilters = () => {
    if (
      searchPage === "archive" ||
      searchPage === "projects" ||
      searchPage === "products" ||
      searchPage === "mySales"
    ) {
      return (
        <FiltersSelect filters={filtersList} onChangeFilter={onChangeFilter} />
      );
    }

    return (
      <Filters
        filters={filtersList}
        filterOrder={filterOrder}
        actions={actions}
        onChangeFilter={onChangeFilter}
      />
    );
  };

  const renderVisibleFilters = () => {
    if (searchPage !== "products") return;

    return (
      <Filters
        filters={visibleList}
        filterOrder={visibleOrder}
        onChangeFilter={onChangeFilter}
        visibility={true}
      />
    );
  };

  const getCalendar = (_, dateStr) => {
    if (!dateStr[0] || !dateStr[1]) {
      return; //productsContext.setPeriod("month");
    }

    const start = dayjs(dateStr[0]).toDate();
    const endDate = dayjs(dateStr[1]).toDate();

    const isToday = new Date(endDate).toLocaleDateString();
    const stringToday = new Date(today).toLocaleDateString();

    let end = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
      23,59,59,999
    );

    if (isToday === stringToday) {
      end = today;
    }

    productsContext.setFollowing({
      startDate: start,
      endDate: end,
      period: "custom",
      stamp: today.getTime(),
    });
  };

  const renderDatePicker = () => {
    if (searchPage !== "mySales") return;

    return (
      <div className="date-range__container">
        <RangePicker
          locale={navigator.language}
          defaultValue={dateRange}
          value={dateRange}
          size="middle"
          onChange={getCalendar}
          minDate={dayjs(MIN_DATE)}
          maxDate={dayjs(today)}
        />
      </div>
    );
  };

  return (
    <div className="admin-navigate d-flex df-column jc-sb">
      <div className="admin-navigate__menu d-flex df-column">
        <Button
          theme="link-gray"
          size="lg"
          iconRight={RefreshIcon}
          className="mr-32 refresh-page"
          onClick={onUpdateData}
        />
        <div className="admin-navigate__tab d-flex df-column">
          <Link
            to={ADMIN_PROJECTS_URL}
            className={`tab-link ${isActiveButton(ADMIN_PROJECTS_URL)}`}
            theme="text"
          >
            Projects
          </Link>
          {isAdmin && (
            <Link
              to={ADMIN_USERS_URL}
              theme="text"
              className={`tab-link ${isActiveButton(ADMIN_USERS_URL)}`}
            >
              Users
            </Link>
          )}
          {isAdmin && (
            <Link
              to={ADMIN_DASHBOARD_URL}
              className={`tab-link ${isActiveButton(ADMIN_DASHBOARD_URL)}`}
              theme="text"
            >
              Products
            </Link>
          )}
          <Link
            to={ADMIN_ARCHIVE_PROJECTS_URL}
            className={`tab-link ${isActiveButton(ADMIN_ARCHIVE_PROJECTS_URL)}`}
            theme="text"
          >
            Archive
          </Link>
        </div>
      </div>
      {searchPage !== "dashboard" && (
        <div className="admin-navigate__search d-flex df-column">
          {renderVisibleFilters()}
          {renderDatePicker()}
          <SearchFormBg
            onSubmit={onSubmitSearch}
            onChange={onChangeSearch}
            placeholder={searchPlaceholder()}
            value={searchValue}
          />
          {renderFilters()}
        </div>
      )}
    </div>
  );
};

export default HorizontalTabsAdmin;
