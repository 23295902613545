import { Icon } from '@ui-kit';
import DollarOnIcon from '@ui-kit/icons/dollar-gray500.svg';
import DollarOffIcon from '@ui-kit/icons/dollar-off-gray500.svg';
import EditIcon from '@ui-kit/icons/edit-3-gray500.svg';
import EyeOnIcon from '@ui-kit/icons/eye-gray500.svg';
import EyeOffIcon from '@ui-kit/icons/eye-off-gray500.svg';
import TrashIcon from '@ui-kit/icons/trash-gray500.svg';
import RocketOnIcon from '@ui-kit/icons/rocket-gray500.svg';
import RocketOffIcon from '@ui-kit/icons/rocket-off-gray500.svg';

export const ITEM_ACTIONS_LIST = [
    {component: (
        <div id="promote" className="link">
            <div className='pd'>
                <Icon src={RocketOnIcon} className="mr-12" />
                <h6>Promote</h6>
            </div>
        </div>
    )},
    {component: (
        <div id="notPromote" className="link">
            <div className='pd'>
                <Icon src={RocketOffIcon} className="mr-12" />
                <h6>Cancel promote</h6>
            </div>
        </div>
    )},
    {component: (
        <div id="notFree" className="link">
            <div className='pd'>
                <Icon src={DollarOnIcon} className="mr-12" />
                <h6>Make paid</h6>
            </div>
        </div>
    )},
    {component: (
        <div id="free" className="link">
            <div className='pd'>
                <Icon src={DollarOffIcon} className="mr-12" />
                <h6>Make free</h6>
            </div>
        </div>
    )},
    {component: (
        <div id="edit" className="link">
            <div className='pd'>
                <Icon src={EditIcon} className="mr-12" />
                <h6>Edit</h6>
            </div>
        </div>
    )},
    {component: (
        <div id="hide" className="link">
            <div className='pd'>
                <Icon src={EyeOffIcon} className="mr-12" />
                <h6>Hide</h6>
            </div>
        </div>
    )},
    {component: (
        <div id="activate" className="link">
            <div className='pd'>
                <Icon src={EyeOnIcon} className="mr-12" />
                <h6>Activate</h6>
            </div>
        </div>
    )},
    {component: (
        <div id="delete" className="link">
            <div className='pd'>
                <Icon src={TrashIcon} className="mr-12" />
                <h6>Delete</h6>
            </div>
        </div>
    )},
  ];

  export const STATUS_ACTIONS_LIST = [
    /*{name: 'Free today', order: 'fieldType', value: 'free'},
    {name: 'Potentially free', order: 'fieldType', value: 'potentially'},*/
    {name: 'Extended commercial', order: 'isCommercial', value: '1'},
    {name: 'Promoted', order: 'isPromoted', value: '1'},
    {name: 'Free', order: 'isFree', value: '1'},
  ];