import React from "react";
import './downloadFree.scss';

import { Text, Icon, Link } from '@ui-kit';
import { BROWSE_URL } from "@app/routes";
import Check from '@ui-kit/icons/check-accent500-md.svg';
import { DOWNLOADS_OPTIONS } from './downloadFree.consts';

const DownloadFree = () =>{
    return (
       <div className="download-free__container-banner d-flex ai-center">
        <div className="download-free__banner">
            <div className="banner_bg pr">
                <div className="banner__perspective"></div>
                <div className="banner__clip"></div>
                <Icon src={'/images/banner-download-free.png'} className="banner__img" alt="banner"/>
            </div>
        </div>
        <div className="download-free__title">
            <Text as='h1'>
                Download{" "}
                <Text as="span" className="highlight">Free</Text>
                {" "}Illustrations Today
            </Text>
            <div className="download-free__sup-title">{
                DOWNLOADS_OPTIONS.map((item, index) => (
                    <div key={`download-free__sup-title-${index}`} className="d-flex ai-center mt-20">
                        <Icon src={Check} className="banner__ico" alt="banner"/>
                        <Text as="h4">{item.title}</Text>
                    </div>
                ))
            }
            </div>
            <Link to={BROWSE_URL} className="download-free__action">
                Get Your Free Illustration Now!
            </Link>
        </div>
       </div>
    )
}

export default DownloadFree;