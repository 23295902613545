import React, { useState } from 'react';
import './modalNotification.scss';
import { useDispatch } from 'react-redux';
import { Dropdown, Text, Button, Input, Icon, CheckBox } from '@ui-kit';
import { Notification, updateAdminProjectPropertyTC } from '@entities';
import Dollar from '@ui-kit/icons/dollar-gray500.svg';
import Clock from '@ui-kit/icons/clock-gray500.svg';
//import Crown from '@ui-kit/icons/crown-gray500.svg';
import Process from '@ui-kit/icons/edit-3-gray500.svg';
import Download from '@ui-kit/icons/download-gray500.svg';

const PRODUCT_DROPDOWN_LIST = [
  new Notification({
    message: 'Sketch is ready',
    markType: 'sketchReady',
    type: 'projectNotification',
    ico: Dollar,
  }),
  new Notification({
    message: 'Illustration is ready',
    markType: 'illustrationReady',
    type: 'projectNotification',
    ico: Dollar,
  }),
  new Notification({
    message: 'Waiting for a response',
    markType: 'waitingResponse',
    type: 'projectNotification',
    ico: Clock,
  }),
  // new Notification({
  //   message: 'Switching to exclusive license',
  //   markType: 'switchingToExclusive',
  //   type: 'projectNotification',
  //   ico: Crown,
  // }),
  new Notification({
    message: 'Work in progress',
    markType: 'workProgress',
    type: 'projectNotification',
    ico: Process,
  }),
  new Notification({
    message: 'Source files are ready',
    markType: 'sourceReady',
    type: 'projectNotification',
    ico: Download,
  }),
];

const ModalNotification = ({
  showNotification = () => {},
  notificationsToUser = [],
  rate = {},
  unpaidHours
}) => {
  const option = notificationsToUser[0] || {};

  const [active, setActive] = useState(option?.value || '');
  const [hours, setHours] = useState(''); //option?.hours || ''
  const [minutes, setMinutes] = useState(''); //option?.minutes || ''
  const [errors, setErrors] = useState({});
  const [invoiceAmount, setInvoiceAmount] = useState('');
  const [isItemCheck, setItemCheck] = useState(false);

  const dispatch = useDispatch();

  PRODUCT_DROPDOWN_LIST.forEach((notification) => {
    notification.value = notification.markType;
    notification.name = notification.message;
  });

  const onChooseNotification = (item) => {
    setActive(item.value);
  };

  const renderDropDowns = (item) => {
    const classes = ['input-search__item'];
    const { value, ico, message } = item;

    if (value === active) {
      classes.push('active');
    }

    return (
      <div key={value} className={classes.join(' ')}>
        {item.ico && <Icon src={ico} alt={value} />}
        <Text as="h5" onClick={() => onChooseNotification(item)}>
          {message}
        </Text>
      </div>
    );
  };

  const onBackgroundClick = (e) => {
    if (e.target.id === 'notification-background') {
      showNotification(false);
    }
  };

  const setCheck = (value) => {
    if (!value) {
      const resetValue = getNotificationValue();
      setInvoiceAmount(resetValue?.notificationValue)
    }

    setItemCheck(value)
  };

  const getNotificationValue = () => {
    setErrors({});
    let errors = {};
    let isError = false;

    const rateValueToNumber = +rate.value;
    const minutesToNumber = +minutes;
    const hoursToNumber = +hours;
    let totalToFloat = +`${hours}.${minutesToNumber}`;

    if (isNaN(totalToFloat)) {
      totalToFloat = 0;
    }

    if (totalToFloat > +unpaidHours) {
      isError = true;
      errors = { ...errors, hours: `Maximum fields value is ${unpaidHours}`, minutes: ' ' };
    }

    const step = 15;

    if (!!minutesToNumber) {
      if (minutesToNumber < step) {
        isError = true;
        errors = { ...errors, minutes: `The minimum value is ${step}` };
      }

      if (!!(minutes % step)) {
        isError = true;
        errors = {...errors,
          minutes: `Step between values must be ${step} (15, 30, 45)`,
        };
      }
    }

    if (invoiceAmount > 0 && invoiceAmount < 1) {
      isError = true;
      errors = {...errors,
        invoiceAmount: `Value must be 0 or minimum $1`,
      };
    }

    setErrors(errors);

    const minutesValue = ((minutesToNumber * rateValueToNumber) / 60).toFixed(
      2
    );
    const notificationValue =
      hoursToNumber * rateValueToNumber + parseFloat(minutesValue);

    return {notificationValue: notificationValue, hours: hoursToNumber, minutes: minutesToNumber, error: isError};
  }

  const onSetValues = (value, type) => {
    if (type === 'minutes') {
      setMinutes(+value)
    }
    if (type === 'hours') {
      setHours(+value)
    }
    if (type === 'invoice') {
      let normalizeValue = Math.abs(+value);
      
      return setInvoiceAmount(normalizeValue);
    }

    const rateValueToNumber = +rate.value;
    const minutesToNumber = type === 'minutes' ? +value : +minutes;
    const hoursToNumber = type === 'hours' ? +value : +hours;

    const minutesValue = ((minutesToNumber * rateValueToNumber) / 60).toFixed(
      2
    );
    const notificationValue = 
      hoursToNumber * rateValueToNumber + parseFloat(minutesValue);
    
    setInvoiceAmount(notificationValue);
  }

  const onPublish = () => {
    const notificationEntity = PRODUCT_DROPDOWN_LIST.find(
      (notification) => notification.value === active
    );

    if (!notificationEntity) {
      return;
    }

    const {notificationValue = 0, hours = 0, minutes = 0, error} = getNotificationValue();

    if(error) return;

    const invoiceValue = isItemCheck ? invoiceAmount : notificationValue;

    notificationEntity['hours'] = hours;
    notificationEntity['minutes'] = minutes;
    notificationEntity['notificationValue'] = invoiceValue;
    notificationEntity['rate'] = rate.type;

    if (notificationEntity.id) {
      dispatch(
        updateAdminProjectPropertyTC({
          name: 'notificationsToUser',
          value: JSON.stringify([notificationEntity]),
          callback: () => {
            showNotification(false);
          },
        })
      );
    }
  };

  return (
    <div
      id="notification-background"
      className="background-default"
      onMouseDown={onBackgroundClick}
    >
      <div className="notification-popup__wrapper">
        <Text as="h4" className="notification-popup__title">
          Notice to the client
        </Text>
        <Text as="h6" className="notification-popup__subtitle">
          Please complete all fields before posting.
        </Text>
        <div className="notification-popup__list">
          <Text as="h6" className="notification-popup__list-title">
            Notification
          </Text>
          <Dropdown
            data={PRODUCT_DROPDOWN_LIST}
            placeholder="Choose notification"
            value={active}
            id="notification-dropdown"
            renderOption={renderDropDowns}
          />
        </div>
        <div className="notification-hours-minutes">
          <Input
            placeholder="Hours"
            name="hours"
            type="number"
            label="Hours"
            value={hours}
            minLengthCustom={0}
            min={0}
            maxLengthCustom={99}
            max={99}
            className={errors.hours && 'form-item__error'}
            onChange={(e) => onSetValues(e.target.value, 'hours')}
          />
          <Input
            placeholder="Minutes"
            name="minutes"
            type="number"
            label="Minutes"
            value={minutes}
            minLengthCustom={0}
            min={0}
            maxLengthCustom={59}
            max={59}
            className={errors.minutes && 'form-item__error'}
            onChange={(e) => onSetValues(e.target.value, 'minutes')}
          />
        </div>
        {errors.hours && (
          <Text as="span" className="error">
            {errors.hours}
          </Text>
        )}
        {errors.minutes && (
          <Text as="span" className="error">
            {errors.minutes}
          </Text>
        )}

        <div className="notification-popup__invoice">
          <Input
            placeholder="Invoice amount"
            name="invoice"
            type="number"
            label="Invoice amount"
            value={invoiceAmount}
            minLengthCustom={0}
            min={0}
            maxLengthCustom={100000}
            max={100000}
            className={errors.invoiceAmount && 'form-item__error'}
            onChange={(e) => onSetValues(e.target.value, 'invoice')}
            isDisabled={!isItemCheck}
          />
          <CheckBox 
            className="checkbox__item"
            checked={isItemCheck}
            onChange={(e) => setCheck(e.target.checked)}
            label="Enter another amount"
          />

          {errors.invoiceAmount && (
            <Text as="span" className="error">
              {errors.invoiceAmount}
            </Text>
          )}
        </div>

        <Button className="notification__btn publish" onClick={onPublish}>
          Publish
        </Button>
        <Button
          className="notification__btn"
          theme="secondary-gray"
          onClick={() => showNotification(false)}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default ModalNotification;
