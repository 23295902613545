import React from 'react';
import { Popover } from 'antd';
import './table-sales.scss';

import { Text } from '@ui-kit';
import { ModalImage } from '@features';

const SalesItem = ({ item = {} }) => {
  const { 
    product = {}, 
    paidAt = '-',
     value = 0, 
     firstName = '', 
     lastName = '',
     email = '',
  } = item;
  const { 
    pathToBrowse = '', 
    pathToPreview = '',
    title = '',
    id = 0,
    rate_type = 'Extended commercial',
    isPromoted = 0,
    isFree = 0,
  } = product;

  const saleDate = paidAt ? new Date(paidAt).toLocaleString(undefined, {
    year: 'numeric', 
    month: '2-digit', 
    day: '2-digit'
  }) : '-';
  const saleTime = paidAt ? new Date(paidAt).toLocaleTimeString(undefined, {
    hour: 'numeric', 
    minute: 'numeric',
    second: 'numeric',
  }) : '-';

  const PRODUCT_URL = process.env.REACT_APP_PRODUCTS_URL;

  const customerName = () => {
    if (!firstName) return '';
    if (firstName && !lastName) {
      return firstName;
    };
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    };
  };

  const renderStatus = () => {
    const statusList = []
    if (+isPromoted) statusList.push('Promoted');
    if (+isFree) statusList.push('Free');
    if (!statusList.length || !+isFree) statusList.push(rate_type || 'Extended commercial');

    return statusList.join(', ');
  }

  const content = (
    <Text as="h5">{title || ''}</Text>
  );

  return (
    <div className="admin__table-row">
      <div className="product-name d-flex ai-center">
        <ModalImage
          key={`img-${id}`}
          src={`${PRODUCT_URL}/${pathToBrowse}`}
          backgroundSrc={`${PRODUCT_URL}/${pathToPreview}`}
        />

        <div className="product-title">
          <Popover
            overlayStyle={{overflow: 'initial'}}
            overlayClassName="product-title__pop"
            content={content}
            trigger={'hover'}
            arrow={true}
            placement={'topLeft'}
          >
            <>
              <Text as="h5">{title || ''}</Text>
            </>
          </Popover>
            <Text as="h6">#{id}</Text>
        </div>
      </div>

      <div className="product-customer">
          <Text as="h5">{customerName()}</Text>
          <Text as="h6">{email}</Text>
      </div>

      <div className="product-date">
          <Text as="h5">{saleDate || ''}</Text>
          <Text as="h6">{saleTime || ''}</Text>
      </div>

      <div className="product-status">
          <Text as="h6">{renderStatus()}</Text>
      </div>

      <div className="product-earned">
          <Text as="h6">{value}</Text>
      </div>

    </div>
  );
};

export default SalesItem;
