import React, { useState, useEffect } from "react";
import { Carousel, Tooltip } from "antd";
import "./dailyNews.scss";

import { Text, Icon } from "@ui-kit";
import { NEWS } from "./dailyNews.const";

const DailyNews = () => {
  const [slidesToShow, setSlidesToShow] = useState(1);

  const updateSlidesToShow = () => {
    const width = window.innerWidth;
    if (width < 606) {
      setSlidesToShow(1);
    } else if (width < 1000) {
      setSlidesToShow(2);
    } else {
      setSlidesToShow(3);
    }
  };

  useEffect(() => {
    updateSlidesToShow();
    window.addEventListener('resize', updateSlidesToShow);

    return () => {
      window.removeEventListener('resize', updateSlidesToShow);
    };
  }, []);

  return (
    <div className="daily-news__container">
      <div className="main__container daily-news__content">
        <div className="daily-news__header">
          <Text as="h1">
            Daily News
          </Text>
          <Text as="h3">
            We are growing for you! Latest updates and new items in our stock
          </Text>
        </div>
        <div className="daily-news__items">
          <Carousel
            autoplay
            slidesToShow={slidesToShow}
            arrows
            //infinite={true}
            draggable={true}
          >
            {NEWS.map((item, index) => {
              return (
                <div className="daily-news__item" key={`item-${index}`}>
                  <Text as="h5" className="date mb-10">
                    {item.date}
                  </Text>
                  <Text as="h5" className="title mb-6">
                    {item.title}
                  </Text>
                  <Tooltip
                    title={item.text}
                  >
                    <>
                      <Text as="h5" className="text mb-10">
                        {item.text}
                      </Text>
                    </>
                  </Tooltip>
                  <div className="user-info">
                    <div className="avatar-label__container d-flex ai-center">
                      <div className="avatar__wrapper d-flex jc-center ai-center pr xl">
                        <div className="avatar__img-wrapper">
                          <Icon src={item.user.avaStatic} alt="avatar" />
                        </div>
                      </div>
                      <div className="author__wrapper">
                        <Text as="h6" className="author__name">
                          <Text as="span" className="mr-8">{item.user.firstName}</Text>
                        </Text>
                        <Text as="h6" className="author__text">{item.user.role}</Text>
                      </div>
                    </div>
                    
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default DailyNews;
