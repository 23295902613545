import React from 'react';
import { useSelector } from "react-redux";
import { List, Skeleton } from "antd";
import "./blogList.scss";

import { AvatarLabel } from "@features";
import { Text, Icon } from "@ui-kit";
import { BLOG_URL } from "@app/routes";
import { getBlog, getIsSettingsLoading } from "@entities";

const BlogList = () => {
    const blog = useSelector(getBlog);
    const isLoading = useSelector(getIsSettingsLoading);
    const { REACT_APP_BLOG_IMG_URL } = process.env;

    const formattedDate = (dateStr) => {
        const date = new Date(dateStr);
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return date.toLocaleString('en-US', options);
    }

    return (
        <div className="blog-list__container">
            <div className="blog-list__title">
              <Text as="h4">News from the Art4Free team</Text>
            </div>
            <Skeleton loading={isLoading} active avatar>
              <List
                dataSource={blog}
                itemLayout="vertical"
                size="large"
                /*pagination={{
                  onChange: () => {},
                  pageSize: 5,
                  align: "center",
                }}*/
                renderItem={(item) => (
                  <List.Item
                    key={item.title}
                    actions={[
                      <AvatarLabel
                        author={item?.user}
                        ava={item?.user?.ava}
                        text={item?.user?.text}
                        size="xl"
                      />
                    ]}
                    extra={item?.img &&  
                      <Icon
                        src={`${REACT_APP_BLOG_IMG_URL}/${item.img}`}
                        alt="image"
                      />
                    }
                  >
                    <List.Item.Meta
                      avatar={<Text as="h6">{formattedDate(item.createdAt)}</Text>}
                      title={
                        <a href={`${BLOG_URL.pathname}/${item.id}`}>
                          {item.title}
                        </a>
                      }
                      description={item.description}
                    />
                  </List.Item>
                )}
              />
            </Skeleton>
          </div>
    )
}

export default BlogList;