import React from "react";
import "./whyArt.scss";

import { Text, Icon } from "@ui-kit";
import { RIGHT_PRODUCTS } from "./whyArt.consts";

const WhyArt = () => {
  return (
    <div className="why-art__main-container">
      <div className="main__container d-flex">
        <div className="why-art__description">
          <Text as="h1" className="title mb-20">
            Why Art4Free?
          </Text>
        </div>
        <div className="why-art__content fw-wrap d-flex">
          {RIGHT_PRODUCTS.map((item, index) => (
            <div key={`item-${index}`} className="why-art__item">
              <div className="icon">
                <Icon src={item.ico} alt={item.alt} />
              </div>
              <div className="why-art__item-content">
                <Text as="h3" className="title">
                  {item.title}
                </Text>
                <Text as="h5" className="description">
                  {item.description}
                </Text>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyArt;
