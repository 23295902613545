import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import "./newImages.scss";

import { CarouselArrow, Icon, Link, Text } from "@ui-kit";
import { BROWSE_URL } from '@app/routes';
import { HoverProductCard } from "@widgets";
import { setModalGetFreeProductTC, getCurrentProducts } from "@entities";

const { REACT_APP_PRODUCTS_URL } = process.env;

const NewImages = () => {
  const dispatch = useDispatch();
  const products = useSelector(getCurrentProducts);
  const similarRef = useRef();
  const [lastSlide, setLastSlide] = useState(true);
  const [firstSlide, setFirstSlide] = useState(false);
  const [viewItems, setViewItems] = useState(products);
  const [hideElements, setHideElements] = useState({});

  const MOBILE_WIDTH = 590;

  const filteredSimilars = () => {
    if (viewItems.length) {
      filterItems();
    }
  };

  useEffect(() => {
    filteredSimilars();

    window.addEventListener("resize", () => filteredSimilars());
    // eslint-disable-next-line
    return () => window.removeEventListener("resize", () => filteredSimilars());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (products.length) {
       setViewItems(products);
    }
    // eslint-disable-next-line
  }, [products.length])

  useEffect(() => {
    if (viewItems.length) {
      renderItems();
    }
    // eslint-disable-next-line
  }, [viewItems.length]);

  const sliderSettings = {
    infinite: true,
    variableWidth: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    prevArrow: <CarouselArrow direction="prev" />,
    nextArrow: <CarouselArrow direction="next" />,
    responsive: [
      {
        breakpoint: 606,
        settings: {
          prevArrow: (
            <CarouselArrow direction="prev" isDisabled={!firstSlide} />
          ),
          nextArrow: <CarouselArrow direction="next" isDisabled={!lastSlide} />,
          afterChange: (currentSlide) => {
            if (currentSlide + 1 === viewItems.length - 1) {
              setLastSlide(false);
            } else {
              setLastSlide(true);
            }
            if (currentSlide) {
              setFirstSlide(true);
            } else {
              setFirstSlide(false);
            }
          },
        },
      },
    ],
  };

  if (!products.length) {
    return;
  }

  const filterItems = () => {
    const windowWidth = document.body.clientWidth || 0;
    const sliderWidth = similarRef?.current?.getBoundingClientRect().width || 0;
    const sliderItems = Array.from(
      document.querySelectorAll(".new-imgs__item")
    );

    if (!sliderWidth || !windowWidth || windowWidth < MOBILE_WIDTH) {
      for (const key in hideElements) {
        delete hideElements[key];
      }

      setHideElements(hideElements);
      setViewItems(products);
      return;
    }

    let filteredItems = [...viewItems];
    sliderItems.map((sliderItem) => {
      const hideWidth = hideElements[sliderItem.id]?.width;

      if (sliderItem?.clientWidth > sliderWidth && hideWidth === undefined) {
        hideElements[sliderItem.id] = { width: sliderItem.clientWidth };
        filteredItems = filteredItems.filter(
          (item) => +item.id !== +sliderItem.id
        );
      }

      return sliderItem;
    });

    Object.keys(hideElements).map((id) => {
      if (hideElements[id].width > sliderWidth) {
        hideElements[id] = { width: hideElements[id].width };
        filteredItems = filteredItems.filter((item) => +item.id !== +id);
        return id;
      }

      if (hideElements[id].width < sliderWidth) {
        const unhideItem = products.filter((item) => +item.id === +id)[0];
        filteredItems.push(unhideItem);

        delete hideElements[id];
      }

      return id;
    });

    setViewItems(filteredItems);
    setHideElements(hideElements);
  };

  const getFree = (event, slide) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(setModalGetFreeProductTC({ show: true, product: slide }));
  };

  const renderItems = () => {
    if (!viewItems.length) return;
    return viewItems.map((slide, index) => {
      const { linkId, id, pathToBrowse, title, html_description, user } = slide;
      return (
        <div
          key={Date.now() + index}
          className="new-imgs__item mr-8 slide__item pr background__static"
          id={id}
        >
          <a
            href={`${BROWSE_URL.pathname}/${linkId}-${id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon
              className="new-imgs__img"
              src={`${REACT_APP_PRODUCTS_URL}/${pathToBrowse}`}
              title={title}
              alt={html_description}
            />

            {user && (
              <HoverProductCard
                title={title}
                author={user}
                getFree={(e) => getFree(e, slide)}
              />
            )}
          </a>
        </div>
      );
    });
  };

  return (
    <div className="new-imgs__container mt-12">
        <Text as="h1" className="new-imgs__title">
            New Illustrations
        </Text>
      <div className="new-imgs__slider pr" ref={similarRef}>
        <Slider {...sliderSettings}>{renderItems()}</Slider>
      </div>
      <div className="new-imgs__action">
        <Link to={`${BROWSE_URL.pathname}?orderBy=createdAt`} theme="secondary">
          Show all
        </Link>
      </div>
    </div>
  );
};

export default NewImages;
