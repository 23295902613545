import Edit from "@ui-kit/icons/edit-3-accent600.svg";
import Dollar from "@ui-kit/icons/dollar-accent600.svg";
import Download from "@ui-kit/icons/download-accent600.svg";
import Crown from "@ui-kit/icons/crown-accent600.svg";
import ThumbUp from "@ui-kit/icons/thumbs-up-accent600.svg";

export const RIGHT_PRODUCTS = [
  {
    title: "Extended commercial license",
    description: "Use our free illustrations in commercial projects without complicated conditions",
    ico: Dollar,
    alt: "dollar",
  },
  {
    title: "Thousands of professional illustrations for free",
    description: "Try a vast collection of premium digital artworks without subscriptions or hidden costs. Completely free!",
    ico: Crown,
    alt: "crown",
  },
  {
    title: "Unlimited downloads",
    description: "Download as many illustrations as you want: no restrictions or additional fees",
    ico: Download,
    alt: "download",
  },
  {
    title: "High-resolution images",
    description: "Enjoy the premium quality illustrations",
    ico: ThumbUp,
    alt: "thumb up",
  },
  {
    title: "Request edits",
    description: "Order custom edits to create the perfect illustration for your project",
    ico: Edit,
    alt: "edit",
  },
];
