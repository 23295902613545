export const NEWS = [
  {
    date: "24/02/2025",
    title: "Site Launch!",
    text: "Welcome, friends! Our first version is live — enjoy free, business-licensed illustrations.",
    user: {
      avaStatic: "/images/ava/mp.jpg",
      firstName: "Maxim P.",
      role: "Co-founder",
    },
  },
  {
    date: "25/02/2025",
    title: "37,000 Illustrations Uploaded!",
    text: "We've added our entire illustration database for your creative needs.",
    user: {
      avaStatic: "/images/ava/mp.jpg",
      firstName: "Maxim P.",
      role: "Co-founder",
    },
  },
  {
    date: "03/03/2025",
    title: "Fresh Art Weekly!",
    text: "This week, 200 new illustrations. Expect 500 quality artworks every month!",
    user: {
      avaStatic: "/images/ava/mp.jpg",
      firstName: "Maxim P.",
      role: "Co-founder",
    },
  },
  {
    date: "05/03/2025",
    title: "Speed Upgrade!",
    text: "Minor bugs fixed—our site is now faster and smoother for you.",
    user: {
      avaStatic: "/images/ava/mo.jpg",
      firstName: "Mila O.",
      role: "Care service",
    },
  },
  {
    date: "07/03/2025",
    title: "Groovy Collection Growing!",
    text: "Already 229 groovy illustrations available, and more are coming soon.",
    user: {
      avaStatic: "/images/ava/mp.jpg",
      firstName: "Maxim P.",
      role: "Co-founder",
    },
  },
  {
    date: "10/03/2025",
    title: "Search Simplified!",
    text: "We’ve added a search bar for free illustrations in the header for easier navigation.",
    user: {
      avaStatic: "/images/ava/mo.jpg",
      firstName: "Mila O.",
      role: "Care service",
    },
  },
  {
    date: "12/03/2025",
    title: "Thank You!",
    text: "We appreciate your support—stay creative and inspired!",
    user: {
      avaStatic: "/images/ava/mp.jpg",
      firstName: "Maxim P.",
      role: "Co-founder",
    },
  },
];
