export const SET_LOADING                 = 'SET_LOADING';
export const SET_PRODUCTS                = 'SET_PRODUCTS';
export const DELETE_PRODUCT              = 'DELETE_PRODUCT';
export const SET_HOME_GALLERY            = 'SET_HOME_GALLERY';
export const DELETE_CHECKED_PRODUCTS     = 'DELETE_CHECKED_PRODUCTS';

export const SET_DASHBOARD_TOTALS        = 'SET_DASHBOARD_TOTALS';
export const SET_DASHBOARD_MOST          = 'SET_DASHBOARD_MOST';
export const SET_DASHBOARD_GRAPH         = 'SET_DASHBOARD_GRAPH';
export const SET_SALES_PRODUCTS          = 'SET_SALES_PRODUCTS';

export const SET_MOUNTHLY_AMOUNT         = 'SET_MOUNTHLY_AMOUNT';
export const SET_ENTIRE_AMOUNT           = 'SET_ENTIRE_AMOUNT';
export const SET_FREE_AMOUNT             = 'SET_FREE_AMOUNT';
export const SET_PAGINATION_FREE_AMOUNT  = 'SET_PAGINATION_FREE_AMOUNT';
export const SET_PUBLISHED_ENTIRE_AMOUNT = 'SET_PUBLISHED_ENTIRE_AMOUNT';
export const SET_PAGINATION_AMOUNT       = 'SET_PAGINATION_AMOUNT';

export const SET_MS_CONNECTED            = 'SET_MS_CONNECTED';
