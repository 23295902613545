import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import "./table-sales.scss";

import { Text, Button } from "@ui-kit";
import { LoadingAdmin } from "@shared/ui";
import { PaginationInput, FiltersChecked } from "@widgets";
import {
  getSalesProductsTC,
  getPaginationAmount,
  getIsLoading,
} from "@entities";
import { STATUS_ACTIONS_LIST } from "./status-list.consts";
import SalesItem from "./salesItem";
import SortIcon from "@ui-kit/icons/code-gray500.svg";

const TableSalesProducts = ({
  sales = [],
  isDashboard = true,
  followingData = {},
}) => {
  const dispatch = useDispatch();
  const salesPaginationAmount = useSelector(getPaginationAmount);
  const salesIsLoading = useSelector(getIsLoading);

  const [page, setPage] = useState();
  const [limit, setLimit] = useState();
  const [field, setField] = useState();
  const [order, setOrder] = useState();
  // eslint-disable-next-line
  const [dateFrom, setDateFrom] = useState();
  // eslint-disable-next-line
  const [dateTo, setDateTo] = useState();
  const [allPage, setAllPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const isEmpty = !sales.length;

  const [forceRender, setForceRender] = useState(true);

  useEffect(() => {
    if (salesIsLoading) {
      return setForceRender(true);
    }

    setTimeout(() => setForceRender(false), 1000);
  }, [salesIsLoading]);

  useEffect(() => {
    const params = getSearchParams();
    const searchByArray = [];

    params.forEach(({ key, value }) => {
      switch (key) {
        case "page": {
          if (!+value || +value < 1) {
            searchParams.set("page", 1);
            setSearchParams(searchParams, { replace: true });
            return setPage(1);
          }

          setPage(value);
          break;
        }

        case "limit": {
          if (!+value || +value < 1) {
            searchParams.set("limit", 80);
            setSearchParams(searchParams, { replace: true });
            setAllPage(Math.ceil(+salesPaginationAmount / 80));

            return setLimit(80);
          }

          const allPages = Math.ceil(+salesPaginationAmount / +value);

          setAllPage(allPages);
          setLimit(+value);

          if (allPages < page) {
            setPage(allPages);
            searchParams.set("page", allPages);
            setSearchParams(searchParams, { replace: true });
            onUpdateData();
          }

          break;
        }

        case "fieldType": {
          // value, createdAt, customerName, customerEmail
          if (!value || value === "") {
            searchParams.set("fieldType", "createdAt");
            setSearchParams(searchParams, { replace: true });
            setField("createdAt");
            return;
          }

          setField(value);
          break;
        }

        case "orderType": {
          // DESC, ASC
          if (!value || value === "") {
            searchParams.set("orderType", "DESC");
            setSearchParams(searchParams, { replace: true });
            setOrder("DESC");
            return;
          }

          setOrder(value);
          break;
        }

        case "dateFrom": {
          // num sec
          if (!value || value === "") {
            searchParams.set("dateFrom", followingData?.startDate);
            setSearchParams(searchParams, { replace: true });
            setDateFrom(followingData?.startDate);
            return;
          }

          setDateFrom(value);
          break;
        }

        case "dateTo": {
          // num sec
          if (!value || value === "") {
            searchParams.set("dateTo", followingData?.endDate);
            setSearchParams(searchParams, { replace: true });
            setDateTo(followingData?.endDate);
            return;
          }

          setDateTo(value);
          break;
        }

        case "searchBy": {
          searchByArray.push(value);
          break;
        }

        default:
          break;
      }
    });

    if (!params.length) {
      //searchParams.set("page", 1);
      //searchParams.set("limit", 80);

      //setSearchParams(searchParams, { replace: true });
    }

    // eslint-disable-next-line
  }, [searchParams, salesPaginationAmount]);

  const onUpdateData = () => {
    const params = productsParams();

    return setTimeout(() => dispatch(getSalesProductsTC(params)), 500);
  };

  const productsParams = () => {
    const params = window.location.search
      .slice(1)
      .split("&")
      .map((p) => p.split("="))
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

    return {
      ...params,
      page: params.page || 1,
      limit: params.limit || 80,
    };
  };

  const getMyProducts = () => {
    const params = productsParams();

    const options = {
      ...params,
      limit: params.limit || 80,
      page: params.page || 1,
      fieldType: params?.fieldType || 'createdAt',
      orderType: params?.orderType || 'DESC',
    };

    dispatch(getSalesProductsTC(options));
  };

  const getSearchParams = () => {
    const params = [];

    searchParams.forEach((value, key) => {
      params.push({ key, value });
    });

    return params;
  };

  const onChangeStatus = () => {
    getMyProducts();
  };

  const onChangeSort = (currentField) => {
    if (currentField !== field) {
      searchParams.set("fieldType", currentField);
      searchParams.set("orderType", "DESC");
      setSearchParams(searchParams, { replace: true });

      setField(currentField);
      setOrder("DESC");
      return getMyProducts();
    }

    const params = getSearchParams();
    let currentOrder = order;

    params.forEach((param) => {
      if (param.key === "orderType") {
        currentOrder = param.value;
      }
    });

    let newOrder = "";
    if (currentOrder === "DESC") {
      newOrder = "ASC";
    } else if (currentOrder === "ASC") {
      newOrder = "DESC";
    } else {
      newOrder = "DESC";
    }

    searchParams.set("orderType", newOrder);
    setSearchParams(searchParams, { replace: true });

    setOrder(newOrder);
    getMyProducts();
  };

  const onChangeValue = (e) => {
    const value = e?.target?.value;
    setPage(value);
  };

  const onPageChange = (event) => {
    event.preventDefault();

    const page = +event.target.page.value;

    if (!page) {
      return;
    }

    if (page > allPage) {
      setPage(allPage);
      searchParams.set("page", allPage);
      setSearchParams(searchParams, { replace: true });
      return;
    }

    setPage(page);
    searchParams.set("page", page);
    setSearchParams(searchParams, { replace: true });
    getMyProducts();
  };

  const onClickPrev = () => {
    const params = getSearchParams();
    let numberPage = 0;

    params.forEach((param) => {
      if (param.key === "page") {
        numberPage = param.value;
      }
    });

    if (!numberPage || numberPage <= 1) {
      return;
    }

    const page = +numberPage - 1;
    searchParams.set("page", page);
    setSearchParams(searchParams, { replace: true });
    setPage(page);
    getMyProducts();
  };

  const onClickNext = () => {
    const params = getSearchParams();
    let numberPage = 0;

    params.forEach((param) => {
      if (param.key === "page") {
        numberPage = +param.value;
      }
    });

    if (!numberPage || numberPage >= allPage) {
      return;
    }

    const page = +numberPage + 1;
    searchParams.set("page", page);

    setSearchParams(searchParams, { replace: true });
    setPage(page);
    getMyProducts();
  };

  const renderTable = () => {
    if (forceRender) return <LoadingAdmin />;

    if (!sales.length) {
      return (
        <div className="sales-empty">
          <Text as="h6" className="empty-title">
            You have no sales yet
          </Text>
        </div>
      );
    }

    return sales.map((item, index) => (
      <SalesItem key={`product-${index}`} item={item} />
    ));
  };

  return (
    <div className="table-sales__container">
      <div className="admin__table">
        <div className="admin__table-header">
          <Text as="h6" className="product-name">
            Product name
          </Text>

          <Text as="h6" className="product-customer">
            Customer
          </Text>

          <Button
            onClick={() => onChangeSort("createdAt")}
            size="md"
            theme="link-gray"
            iconRight={SortIcon}
            className="product-date"
          >
            Date of sale
          </Button>

          <FiltersChecked
            className="product-status"
            filters={STATUS_ACTIONS_LIST}
            onChangeFilter={onChangeStatus}
          >
            <Text as="h6">Status</Text>
          </FiltersChecked>

          <Button
            onClick={() => onChangeSort("value")}
            size="md"
            theme="link-gray"
            iconRight={SortIcon}
            className="product-earned"
          >
            Earned
          </Button>
        </div>

        <div className="admin__table-body">
          {renderTable()}
        </div>
      </div>

      {(!isDashboard && !!sales.length) && (
        <PaginationInput
          allPage={allPage}
          onSubmit={onPageChange}
          onClickPrev={onClickPrev}
          onClickNext={onClickNext}
          currentPage={page}
          onChangeValue={onChangeValue}
          isDisabled={isEmpty}
          currentLimit={limit}
          viewList={false}
        />
      )}
    </div>
  );
};

export default TableSalesProducts;
