import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './datePickerRange.scss';

import { Button } from '@ui-kit';

const ModalDatePickerRange = ({
  showCalendar = () => {},
  followingData = {},
  setFollowingData = () => {}
}) => {
  const MIN_DATE = +process.env.REACT_APP_MIN_DATE_PICKER;
  const [followingDataLocal, setFollowingDataLocal] = useState(followingData);

  const today = new Date();
  const minDate = new Date(MIN_DATE);
  const selectionRange = {
    startDate: followingDataLocal.startDate,
    endDate: followingDataLocal.endDate,
    key: 'selection',
  }

  const cancel = () => {
    setFollowingDataLocal(followingData);
    showCalendar(false);
  };

  const onBackgroundClick = (e) => {
    if (e.target.id === 'picker-background') {
      cancel();
    };
  };

  const handleSelect = ({ selection }) => {
    let {startDate, endDate} = selection;
    const isToday = new Date(endDate).toLocaleDateString();
    const stringToday = new Date(today).toLocaleDateString();
  
    endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59, 999);

    if(isToday === stringToday) {
      endDate = today;
    }

    setFollowingDataLocal({startDate: startDate, endDate: endDate, period: 'custom', stamp: today.getTime()});
  };

  const onApplyFollowingData = () =>{
    setFollowingData(followingDataLocal);
    showCalendar(false);
  };

  return (
    <div
      id="picker-background"
      className="background-default"
      onMouseDown={onBackgroundClick}
    >
      <div className="date-range__wrapper">
        <DateRangePicker
          ranges={[selectionRange]}
          onChange={handleSelect}
          showMonthAndYearPickers={true}
          editableDateInputs={true}
          dateDisplayFormat={'MMM-dd-yyyy'}
          dayDisplayFormat={'dd'}
          minDate={minDate}
          maxDate={today}
          monthPicker={false}
          showSelectionPreview={false}
          className="date-range__wrapper-item"
          color="#973eb4"
        />
        <div className="date-range__wrapper-active">
          <Button
            className="date-range__btn"
            theme="secondary-gray"
            onClick={cancel}
          >
            Cancel
          </Button>
          <Button className="date-range__btn" onClick={onApplyFollowingData}> Apply</Button>
        </div>
      </div>
    </div>
  );
};

export default ModalDatePickerRange;
