import React from "react";
import "./most-tables.scss";

import { Text, Icon } from "@ui-kit";
import MostItem from "./mostItem";
import DollarIcon from "@ui-kit/icons/dollar-accent600.svg";
import EyeIcon from "@ui-kit/icons/eye-accent600.svg";
import DownloadIcon from "@ui-kit/icons/download-accent600.svg";

const MostTables = ({ data = {} }) => {
  return (
    <div className="most-tables__container">
      <div className="most-table">
        <div className="title">
          <Text as="h4">Best selling</Text>
          <div className="featured-icon">
            <Icon src={DollarIcon} />
          </div>
        </div>

        <div className="admin__table">
          {data?.selling?.length ? data?.selling.map((item, index) => (
            <MostItem item={item} name="selling" key={`most-item-${index}`} />
          )) : <div className="table__empty">...Loading</div>}
        </div>
      </div>

      <div className="most-table">
        <div className="title">
          <Text as="h4">Most downloaded</Text>
          <div className="featured-icon">
            <Icon src={DownloadIcon} />
          </div>
        </div>

        <div className="admin__table">
          {data?.downloaded?.length ? data?.downloaded.map((item, index) => (
            <MostItem
              item={item}
              name="downloaded"
              key={`most-item-${index}`}
            />
          )) : <div className="table__empty">...Loading</div>}
        </div>
      </div>

      <div className="most-table">
        <div className="title">
          <Text as="h4">Most viewed</Text>
          <div className="featured-icon">
            <Icon src={EyeIcon} />
          </div>
        </div>

        <div className="admin__table">
          {data?.viewed?.length ? data?.viewed.map((item, index) => (
            <MostItem item={item} name="viewed" key={`most-item-${index}`} />
          )) : <div className="table__empty">...Loading</div>}
        </div>
      </div>
    </div>
  );
};

export default MostTables;
