import React from "react";
import { Popover } from 'antd';
import "./most-tables.scss";

import { Text } from "@ui-kit";
import { ModalImage } from "@features";

const MostItem = ({ item = {}, name = "" }) => {
  const {
    pathToBrowse = "",
    pathToPreview = "",
    title = "",
    id = 0,
    amount_downloaded = 0,
    amount_earned = 0,
    amount_views = 0,
  } = item;
  const PRODUCT_URL = process.env.REACT_APP_PRODUCTS_URL;

  const renderValue = () => {
    if (name === "selling") return `$${amount_earned}`;
    if (name === "downloaded") return amount_downloaded;
    if (name === "viewed") return amount_views;
  };

  const content = (
      <Text as="h5">{title || ''}</Text>
    );

  return (
    <div className="admin__table-row">
      <div className="product-name d-flex ai-center">
        <ModalImage
          key={`img-${id}`}
          src={`${PRODUCT_URL}/${pathToBrowse}`}
          backgroundSrc={`${PRODUCT_URL}/${pathToPreview}`}
        />

        <div className="product-title">
          <Popover
            overlayStyle={{ overflow: "initial" }}
            overlayClassName="product-title__pop"
            content={content}
            trigger={"hover"}
            arrow={true}
            placement={"topLeft"}
          >
            <>
              <Text as="h5">{title || ""}</Text>
            </>
          </Popover>
          <Text as="h6">#{id}</Text>
        </div>
      </div>
      <div className="product-value">
        <Text as="h5">{renderValue()}</Text>
      </div>
    </div>
  );
};

export default MostItem;
