import React from 'react';
import './modalActivatedEmail.scss';
import { useDispatch } from 'react-redux';
import { Text, Button, Input, Icon, IconFeatured } from '@ui-kit';
import { setActivatedEmailShowAC, sendActivationOnEmailTC } from '@entities';
import WarningIcon from '@ui-kit/icons/alert-triangle-warning600.svg';
import AlertIcon from '@ui-kit/icons/alert-circle-error500.svg';

const ModalActivatedEmail = ({customerEmail, className=''}) => {
    const dispatch = useDispatch();
    const classes = ['activated-email-modal', className];
    const bkgClasses = ['background-default'];
    if(!className) {
        bkgClasses.push('activated-email__bkg');
    };

    const sendActivationOnEmail = () => {
        dispatch(sendActivationOnEmailTC({email: customerEmail}));
        dispatch(setActivatedEmailShowAC(false));
    }

    const onBackgroundClick = (e) => {
        if (e.target.id === 'chossing-activated-background') {
            dispatch(setActivatedEmailShowAC(false));
        }
    };

    return (
        <div
            id="chossing-activated-background"
            className={bkgClasses.join(' ')}
            onMouseDown={onBackgroundClick}
        >
            <div className={classes.join(' ')}>
                <div className="activated-email-modal__content">
                    <div className="title-image">
                        <IconFeatured src={WarningIcon} theme="warning" size="xl"/>
                        <Icon src="/images/tiger_suprise.png" alt="tiger-suprise"/>
                    </div>
                    <div>
                        <Text as="h4" className="title">
                            Email not confirmed
                        </Text>
                        <Text as="h6" className="subtitle">
                            We ask you to go through the last step so that you can take full 
                            advantage of all the Art4Free features. Please, click the button, 
                            go to your email and confirm the completion of registration in an 
                            incoming letter from Art4Free.
                        </Text>
                    </div>
                </div>
                <div className="activated-email-modal__actions">
                    <Input
                        name="email"
                        type="email"
                        label="Email"
                        value={customerEmail || ''}
                        iconRight={AlertIcon}
                        className="form-item__error"
                        isDisabled={true}
                    />
                    <Text as="span" className="error">
                        Email not confirmed.
                    </Text>

                    <Button 
                        onClick={sendActivationOnEmail} 
                        size="lg"
                        className="activated-email-modal__btn"
                    >
                        Resend the email
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ModalActivatedEmail;

