import React, { useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DualAxes } from "@ant-design/charts";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import "./sales-chart.scss";

import { Text } from "@ui-kit";
import {
  getDashboardGraph,
  getDashboardGraphTC,
  setDashboardGraphAC,
} from "@entities";
import { ProductsContext } from "@shared/hooks";
import HorizontalTabsDate from "./horizontalTabsDate";

const SalesChart = () => {
  const { RangePicker } = DatePicker;
  const MIN_DATE = +process.env.REACT_APP_MIN_DATE_PICKER;
  const dispatch = useDispatch();
  const productsContext = useContext(ProductsContext);
  const followingData = productsContext?.followingData || {};
  const data = useSelector(getDashboardGraph);
  const period = productsContext?.period || "month";
  const [dateRange, setDateRange] = useState([]);

  dayjs.locale(navigator.language);
  const today = new Date();
  const config = {
    data,
    height: 300,
    xField: "date",
    scale: {
      color: { range: ["#87A8F2", "#FA64A2"] },
      //y: { nice: false }
    },
    slider: { x: period === "total" ? true : false },
    //padding: 25,
    children: [
      {
        data: data,
        type: "line",
        yField: "value",
        colorField: "type",
        shapeField: "smooth",
        style: { lineWidth: 1 },
        //axis: { y: false },
      },
    ],
  };

  useEffect(() => {
    return () => {
      dispatch(setDashboardGraphAC([]));
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!today) return;

    if (period !== "custom") {
      getPeriodRange(new Date(MIN_DATE), today);

      dispatch(getDashboardGraphTC({period: period}));
    } else {
      getPeriodRange('', '');
    }

    // eslint-disable-next-line
  }, [period, followingData?.stamp]);

  const getPeriodRange = (start, end) => {
    switch (period) {
      case "total": {
      productsContext.setFollowingData({ startDate: start, endDate: end, period: 'total' });
      return setDateRange([dayjs(start), dayjs(end)]);
    }

    case "year": {
      const startDate = new Date(end.getFullYear() - 1, end.getMonth(), end.getDate());

      productsContext.setFollowingData({ startDate: startDate, endDate: end, period: 'year' });
      return setDateRange([dayjs(startDate), dayjs(end)]);
    }

    case "month": {
      const startDate = new Date(end.getFullYear(), end.getMonth() - 1, end.getDate());

      productsContext.setFollowingData({ startDate: startDate, endDate: end, period: 'month' });
      return setDateRange([dayjs(startDate), dayjs(end)]);
    }

    case "day": {
      const startDate = new Date(end.getFullYear(), end.getMonth(), end.getDate() - 1);

      productsContext.setFollowingData({ startDate: startDate, endDate: end, period: 'day' });
      return setDateRange([dayjs(startDate), dayjs(end)]);
    }

    case "custom": {
      const startDate = followingData?.startDate || 1;
      const endDate = followingData?.endDate || 2;

      return setDateRange([dayjs(startDate), dayjs(endDate)]);
    }

    default: {
      const startDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
      const endDate = today;

      productsContext.setPeriod('month')
      return setDateRange([dayjs(startDate), dayjs(endDate)]);
    }
  }
  };

  const getCalendar = (_, dateStr) => {
    if (!dateStr[0] || !dateStr[1]) {
      return productsContext.setPeriod('month')
    }

    const start = dayjs(dateStr[0]).toDate();
    const endDate = dayjs(dateStr[1]).toDate();

    const isToday = new Date(endDate).toLocaleDateString();
    const stringToday = new Date(today).toLocaleDateString();

    let end = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59, 999);

    if(isToday === stringToday) {
      end = today;
    }

    productsContext.setFollowing({
      startDate: start,
      endDate: end,
      period: 'custom',
      stamp: today.getTime()
    })
  };

  const setPeriodButtons = (period) => {
    productsContext.setPeriod(period)
  }

  return (
    <div className="sales-chart__container">
      <div className="title">
        <Text as="h4">Sales statistics over time</Text>
        <div className="date-range__container">
          <RangePicker 
            locale={navigator.language}
            defaultValue={dateRange}
            value={dateRange}
            size="middle"
            onChange={getCalendar}
            minDate={dayjs(MIN_DATE)}
            maxDate={dayjs(today)}
          />
      </div>
      </div>
      <HorizontalTabsDate period={period} setPeriod={setPeriodButtons} />
      <div className="sales-chart">
        <DualAxes {...config} />
      </div>
    </div>
  );
};

export default SalesChart;
