import React, { useEffect, useState } from 'react';
import './cart-shopping.scss';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getCart,
  getCartId,
  deleteProductFromCartTC,
  //resetCartTC,
  //selectAllItemsCartAC,
  selectOneItemsCartAC,
  setTipAmountAC,
} from '@entities';
import { CartList, CartSummary, CartScrollResume } from '@widgets';
import { Text } from '@ui-kit';
import { CHECKOUT_URL } from '@app/routes';

//TO DO after base version
// import { Button, CheckBox } from '@ui-kit';
// import Trash from "@ui-kit/icons/trash-error700.svg";

const CartShopping = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector(getCart);
  //const isEmptyCart = cart.length ? false : true;
  const cartId = useSelector(getCartId);
  const [isSummaryVisible, setIsSummaryVisible] = useState(false);
  const [donate, setDonate] = useState(0);

  /*let isCartChecked = false;
  const oneChecked = cart.find((item) => !item.isChecked);

  if (!isEmptyCart && !oneChecked) {
    isCartChecked = true;
  };*/

  const cartPrice = cart.reduce(
    (accumulator, cartItem) => accumulator + cartItem.price,
    0
  ) 
  const totalPrice = cartPrice + donate;

  useEffect(() => {
    const footer = document.querySelector('.footer');
    footer.classList.add('footer-cart-margin');
  }, []);

  useEffect(() => {
    dispatch(setTipAmountAC(donate));
    // eslint-disable-next-line
  }, [donate])

  const removeProductFromCart = (productId) => {
    dispatch(deleteProductFromCartTC(cartId, productId));
  };

  //TO DO after base version
  /*const clearCart = () => {
    dispatch(resetCartTC(cartId));
  };*/

  /*const selectAll = () => {
    dispatch(selectAllItemsCartAC(!isCartChecked));
  };*/

  const selectOneCartsItem = (id, value) => {
    dispatch(selectOneItemsCartAC({ id, isChecked: value }));
  };

  const payProcess = () => {
    navigate(CHECKOUT_URL);
  };

  return (
    <div className="main__container">
      <Text as="h1" className="cart__title">
        Shopping cart
      </Text>
      <div className="cart__container d-flex jc-sb">
        <div className="cart__shopping-list">
          {/* <div className="cart__select-remove d-flex">
            <CheckBox checked={isCartChecked} label="Select all" onChange={selectAll}/>
              <Button 
                iconLeft={Trash} 
                onClick={clearCart} 
                size="lg"
                theme="tertiary-danger"
                isDisabled={isEmptyCart}
              >
                Delete all
              </Button>
            </div> */}

          <CartList
            items={cart}
            selectOneCartsItem={selectOneCartsItem}
            removeProductFromCart={removeProductFromCart}
          />
        </div>

        {!isSummaryVisible && (
          <CartScrollResume
            step="cart"
            price={totalPrice}
            payProcess={payProcess}
            payLabel="Proceed to checkout"
            text="(VAT not included)"
          />
        )}
        <div className="cart-resume__wrapper">
          <CartSummary
            step="cart"
            cart={cart}
            showTax={false}
            donate={donate}
            setDonate={setDonate}
            cartPrice={cartPrice}
            totalPrice={totalPrice}
            payProcess={payProcess}
            payLabel="Proceed to checkout"
            setIsSummaryVisible={setIsSummaryVisible}
          />
        </div>
      </div>
    </div>
  );
};

export default CartShopping;
