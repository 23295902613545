import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { HoverProductCard } from '@widgets';
import { Icon } from '@ui-kit';
import { setModalGetFreeProductTC } from '@entities';
import { BROWSE_URL } from '@app/routes';

const { REACT_APP_PRODUCTS_URL } = process.env;

const IllustrationCard = ({ product, preloader, isSorting }) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);

  const {
    linkId,
    id,
    pathToBrowse,
    firstName,
    lastName,
    ava,
    correctWidth,
    user ={},
    title,
    html_description
  } = product;

  const src = `${REACT_APP_PRODUCTS_URL}/${pathToBrowse}`
  const executor = ava ? { firstName, lastName, ava } : user;
  const preloadClass = preloader || !isLoaded ? 'preloader' : '';

  const handleScroll = () => {
    const scrollLoad = window.innerHeight * 2 + window.scrollY;

    if (!isSorting && !isLoaded && scrollLoad >= ref.current?.offsetTop) {
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isSorting && !isLoaded) {
      setTimeout(() => handleScroll(), 200);
    }
    // eslint-disable-next-line
  }, [isSorting, preloader])

  if (!pathToBrowse) return;

  const getFree = (event) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(setModalGetFreeProductTC({show: true, product: product}));
  }

  return (
    <li
      className={`product__item block data-id-${id} ${preloadClass}`}
      ref={ref}
      data-width={correctWidth}
      data-id={id}
    >
      <a
        href={`${BROWSE_URL.pathname}/${linkId}-${id}`}
        className="product__link pr background__static"
        target="_blank"
        rel="noopener noreferrer"
      >
        {isLoaded && 
          <Icon
            src={src}
            className="product__img"
            title={title}
            alt={html_description}
          />
        }

        {user?.firstName && (
          <HoverProductCard
            title={title}
            author={executor}
            getFree={getFree}
          />
        )}
      </a>
    </li>
  );
};

export default IllustrationCard;
