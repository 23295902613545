export const DOWNLOADS_OPTIONS = [
    {
        title: 'High-quality illustrations without watermarks'
    },
    {
        title: 'Extended commercial license'
    },
    {
        title: 'New illustrations added to our gallery weekly'
    },
    {
        title: 'Unlimited downloads — completely free!'
    }
]