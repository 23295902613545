import { createSelector } from 'reselect';

export const getSettings = (state) => state.settingsData;

export const getHourlyrateValue = createSelector(
  getSettings,
  (settingsData) => settingsData.hourlyRate
);

export const getIsSettingsLoading = createSelector(
  getSettings,
  (settingsData) => settingsData.isLoading
);

export const getBlog = createSelector(
  getSettings,
  (settingsData) => settingsData.blog
);

export const getBlogPost = createSelector(
  getSettings,
  (settingsData) => settingsData.blogPost
);

export const getPaginationSettings = createSelector(
  getSettings,
  (settingsData) => settingsData.paginationAmount
)
