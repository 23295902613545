import React from "react";
import './bestAndTotally.scss';

import { Text, Link, Icon } from '@ui-kit';
import { BROWSE_URL } from "@app/routes";

const BestAndTotally = () => {
 return (
   <div className="best__container">
     <div className="main__container best__content d-flex ai-center">
       <div className="best__left">
         <Text as="h1" className="mb-20">
            Art4Free: the Best and Totally<br />
            Free Images for Commercial Use
         </Text>
         
         <Link to={BROWSE_URL} >
          Find an illustration
         </Link>
       </div>
       <div className="best__right">
        <Icon src={'/images/banner-best.png'} className="best__banner" alt="banner_best"/>
       </div>
     </div>
   </div>
 );
};

export default BestAndTotally;