
import ReCAPTCHA from 'react-google-recaptcha';
import { onLoad } from '@lib/utils';

let onCloseListener;

// https://stackoverflow.com/a/44984658
function initListener() {


    const recaptchaWindow = ([]).slice.call(document.getElementsByTagName('iframe'))
        .reverse()
        .find(x=>x.src.includes('google.com/recaptcha/api2/bframe'))
        ?.parentNode.parentNode;

    if (window.recaptchaWindow === recaptchaWindow) return;

    if (window.recaptchaObserver) window.recaptchaObserver.disconnect();

    // find the open reCaptcha window
    window.recaptchaWindow = recaptchaWindow;

    // and now we are listening on CSS changes on it
    // when the opacity has been changed to 0 we know that
    // the window has been closed
    window.recaptchaObserver = new MutationObserver(x => +window.recaptchaWindow.style.opacity === 0 && onClose())
        .observe(window.recaptchaWindow, { attributes: true, attributeFilter: ['style'] });

}

// now do something with this information
function onClose() {
    console.log('recaptcha window has been closed')

    onCloseListener && onCloseListener(null);
}

export function startRecaptcha(recaptchaRef) {
  return new Promise(async resolve => {
    const _resolve = () => {
      recaptchaRef.current.execute();
      resolve();
      initListener();
    };

    if (process.env.REACT_APP_CAPTCHA_DISABLE === 'true') {
      _resolve();
    } else {
      await onLoad(() => !!window.grecaptcha);
      window.grecaptcha.ready(_resolve);
    }
  });

}

const Recapcha = (props) => {
  if (process.env.REACT_APP_CAPTCHA_DISABLE === 'true') {
      props.recaptchaRef.current = {
        execute: () => props.onChange('012345'),
        reset: () => {},
      };

    return;
  }

  onCloseListener = props.onChange;

  const inheritedProps = Object.assign({}, props);
  delete inheritedProps.className;

  return (
    <div className={inheritedProps.wrapperClassName}>
      <ReCAPTCHA
        {...inheritedProps}
        ref={inheritedProps.recaptchaRef}
      />
    </div>
  );
};

Recapcha.start = startRecaptcha;

export default Recapcha;
