import React from 'react';
import './pay-project-success.scss';

import { Text, Link, Icon, IconFeatured } from '@ui-kit';
import {
    ADMIN_PROJECTS_URL,
    ADMIN_USER_PROJECTS_URL,
    ADMIN_USER_BILLS_URL,
  } from '@app/routes';
import BagIcon from '@ui-kit/icons/shopping-bag-accent600.svg';
import FileIcon from '@ui-kit/icons/file-accent600.svg';


const PaymentProjectSuccess = ({projectId = '', isUser}) => {
  const renderUrl = () => {
    if(isUser) {
      return ADMIN_USER_PROJECTS_URL.pathname;
    }

    return ADMIN_PROJECTS_URL.pathname;
  }


  return (
    <div className="payment-status-pr__success">
      <div className="payment-status__header">
        <Text as="h5" className="sub__title mb-12">
          Payment was successful
        </Text>
        <Text as="h1" className="title mb-24">
          Thank you for your payment!
        </Text>
        <Text as="h3" className="sup__title">
          We appreciate you have chosen our platform. Each project is of great importance 
          for us. Hope you are satisfied working with us and will return to Art4Free.
        </Text>
      </div>
      <div className="payment-status__content">
        <div className="feature__content">
          <div className="feature__item">
            <div className="icon">
              <IconFeatured src={BagIcon} theme="primary" size="lg"/>
            </div>
            <div  className="feature__item-content">
                <Text as="h3" className="title">
                  Performed work
                </Text>
                <Text as="h5" className="description">
                  To see the work performed, please follow the link below. Via this 
                  link you can return to the project and download the necessary 
                  files, if any. At any stage of the project, don't hesitate to wtite to 
                  the chat to get the required information or assistance from our 
                  account manager.
                </Text>
                <Link
                  to={`${renderUrl()}?user_info_active=info&project_asset_id=${projectId}`}
                  theme="link-color"
                  className="arrow-right"
                >
                  Go to my projects
                </Link>
            </div>
          </div>
          <div className="feature__item">
            <div className="icon">
              <IconFeatured src={FileIcon} theme="primary" size="lg"/>
            </div>
            <div  className="feature__item-content">
                <Text as="h3" className="title">
                  Payment receipt
                </Text>
                <Text as="h5" className="description">
                  To download a paid receipt in the format you need, follow the link 
                  below. For your convenience, we have also sent a payment receipt 
                  to your email.
                </Text>
                <Link
                  to={ADMIN_USER_BILLS_URL.pathname}
                  theme="link-color"
                  className="arrow-right"
                >
                  Go to receipt
                </Link>
            </div>
          </div>
        </div>
        <div className="image-container">
          <div className="image-background"></div>
          <Icon 
            src="/images/project-success.png" 
            className="image__content"
          />
          <Icon 
            src="/images/project-success-sm.png" 
            className="image__bkg-mob"  
          />
        </div>
      </div>
    </div>
  )
}

export default PaymentProjectSuccess;