import { v4 as uuidv4 } from 'uuid';
import { Rate, Price, FollowingDataDTO } from '@entities';

export class EmptyProjectDTO {
  id;
  executors;
  expectedCostHourlyRate;
  expectedCostMaxRate;
  followingData;
  followingId;
  html_description;
  illustration_format;
  isChat;
  isDeleted;
  isMessagesSend;
  notificationsToUser;
  currentStepsHours;
  price;
  rate;
  title;
  stage;
  defaultExecutors = [{ id: uuidv4(), firstName: 'Art4Free' }];
  workingHoursSum;
  createdAt;

  constructor(option) {
    if (!option) {
      option = {};
    }

    this.id = uuidv4();
    this.title = option?.title || 'New project';
    this.executors = option?.executors || this.defaultExecutors;
    this.rate = new Rate(option?.priceValue || null);
    this.price = new Price();
    this.illustration_format = option?.illustration_format || '1:1';
    this.isChat = +option?.isChat || 1;
    this.followingId = option?.followingId ? option.followingId : 2;
    this.expectedCostHourlyRate = option?.expectedCostHourlyRate || 0;
    this.expectedCostMaxRate = option?.expectedCostMaxRate || 0;
    this.html_description = option?.html_description || '';
    this.illustration_format = option?.illustration_format || '1:1';
    this.isDeleted = option?.isDeleted || 0;
    this.isMessagesSend = option?.isMessagesSend || 1;
    this.stage = option?.stage || 1;
    this.currentStepsHours = [];
    this.workingHoursSum = '0.0';

    this.followingData = new FollowingDataDTO().generateData().getState();
    this.notificationsToUser = [];

    this.createdAt = option.createdAt;

    this.renderImageUrl = '/images/project/default.jpg';
  }
}
