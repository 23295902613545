import React, { useState, useEffect } from 'react';
import './productEditInfo.scss';

import productFormValid from './product-validation.hook';
import { useForm } from '@shared';
import { Icon, Text, Button, Input, TextArea } from '@ui-kit';
import {
  CompatibleWith,
  FilesTypes,
  EditKeywords,
} from '@features';

import ArrowIcon from '@ui-kit/icons/arrow-gray700.svg';
import EyeOnIcon from '@ui-kit/icons/eye-gray400.svg';
import EyeOffIcon from '@ui-kit/icons/eye-off-gray400.svg';
import TrashIcon from '@ui-kit/icons/trash-gray400.svg';
import { useNavigate } from 'react-router-dom';
import { 
  updateProductSettingsTC,
  updateProductTC,
  setEditProductValuesAC
} from '@entities';
import { useDispatch } from 'react-redux';

const ProductEditInfo = ({ product = {}, setModalDeleteProduct = ()=>{} }) => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const PRODUCT_URL = process.env.REACT_APP_PRODUCTS_URL;
  const dispatch = useDispatch();

  const [isUnsaved, setIsUnsaved] = useState(true);

  const {
    id,
    pathToBrowse,
    compatibility_draw = '',
    illustration_formats = '',
    isPublic,
  } = product;

  const { watch, formData, onChangeInput, onSubmitForm, setFormData } = useForm(
    product,
    handlerBeforeSubmitingForm
  );

  useEffect(() => {
    if (!id) return;

    setFormData(product);

    return () => { setFormData({})}
    // eslint-disable-next-line
  }, [product]);

  useEffect(() => {
    const isEditForm = watch();

    if (isEditForm) {
      setIsUnsaved(false);
    }
    // eslint-disable-next-line
  }, [formData]);

  async function handlerBeforeSubmitingForm() {
    const formErrors = productFormValid(formData);

    if (Object.keys(formErrors).length) {
      return setErrors(formErrors);
    }

    dispatch(updateProductTC(id, formData));
    dispatch(setEditProductValuesAC({
      ...product,
      ...formData
    }));
    setErrors({});
    setIsUnsaved(true);
  };

  const onBack = () => {
    setErrors({});
    navigate(-1);
  };

  const onChangePublic = () => {
    const isPublicUpdate = !isPublic;

    const response = dispatch(updateProductSettingsTC(id, { isPublic: isPublicUpdate }));

    if (response) {
      dispatch(setEditProductValuesAC({
        ...product,
        isPublic: isPublicUpdate
      }));
    }
  };

  const onDelete = () => {
    setModalDeleteProduct({status: true, productsId: [id]})
  };

  const onAddKeywords = (keywordsStr) => {
    const clearKeywords = keywordsStr.trim()
      .replaceAll('  ', ' ')
      .replaceAll(';', ',')
      .replaceAll(', ', ',')
      .replaceAll(' ', ',')
      .replaceAll(',,', ',')
      .toLowerCase();

    if (!clearKeywords) return;

    const newKeyWords = [...formData?.key_words.filter(el => el !== '')];
    let flag = false;

    clearKeywords.split(',')
      .forEach(kw => {
        if (!kw || newKeyWords.map(nkw => nkw.toLowerCase()).includes(kw.toLowerCase())) {
          return;
        }
          
        flag = true;
        newKeyWords.unshift(kw);
      });

    if(flag) {
      onChangeInput({target: {name: 'key_words', value: newKeyWords }});
    }
  };

  const onDeleteKeyword = (deleteKeyword) => {
    const updateKeywords = formData.key_words?.filter(kw => kw.toLowerCase() !== deleteKeyword.toLowerCase());

    onChangeInput({target: {name: 'key_words', value: updateKeywords }});
  };

  return (
      <div className="product-edit__container">
        <div className="product-edit__title-actions d-flex jc-sb">
          <Button
            theme="secondary-gray"
            size="lg"
            iconLeft={ArrowIcon}
            onClick={onBack}
          >Back</Button>
          <Button
            size="lg"
            onClick={onSubmitForm}
            isDisabled={isUnsaved}
          >Save</Button>
        </div>
        <div className="product-edit__info-container">
          <div className="edit__info-title d-flex jc-sb">
            <Text as="h4">Product settings</Text>
            <div className="info-title__actions d-flex">
              <Button
                key={formData.isPublic}
                theme="link-gray"
                size="lg"
                iconRight={formData.isPublic ? EyeOffIcon : EyeOnIcon}
                className="hide-product"
                onClick={onChangePublic}
              />
              <Button 
                theme="link-gray"
                size="lg"
                iconRight={TrashIcon}
                className="delete-product"
                onClick={onDelete}
              />
            </div>
          </div>
          <div className="edit__settings d-flex">
            <div className="edit__image-container">
            <Icon 
              src={`${PRODUCT_URL}/${pathToBrowse}`}
              alt={`info-img-${id}`}
              className="mb-24"
            />
            <EditKeywords
                onAddKeywords={onAddKeywords}
                onDeleteKeyword={onDeleteKeyword}
                keywords={formData.key_words}
                length={20}
                errors={errors}
              />
            </div>
            <form className="edit__form">
              <Input 
                name="title"
                type="text"
                label="Name"
                className={`edit__name mt-22${errors?.title ? ' error':''}`}
                value={formData?.title || ''}
                onChange={onChangeInput}
                maxLengthCustom={50}
              />
              {errors.title && (
                <Text as="span" className="error">
                  {errors.title}
                </Text>
              )}
              <TextArea 
                name="html_description"
                label="Description"
                className={`edit__description mt-24${errors?.html_description ? ' error':''}`}
                value={formData?.html_description || ''}
                onChange={onChangeInput}
                maxLengthCustom={255}
              />
              {errors.html_description && (
                <Text as="span" className="error">
                  {errors.html_description}
                </Text>
              )}
              <CompatibleWith compatibility_draw={compatibility_draw} />
              <FilesTypes illustrationFormats={illustration_formats} />
            </form>
          </div>
        </div>
      </div>
  );
};

export default ProductEditInfo;
