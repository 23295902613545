import React from "react";
import "./totalproducts.scss";
import { useSelector } from "react-redux";
import {
  getCurrenteSearch,
  getPaginationAmount,
  getCurrentProducts,
  getPublishedEntireAmount,
} from "@entities";
import { Text } from "@ui-kit";

const TotalProduct = () => {
  const totalAmount = useSelector(getPublishedEntireAmount);
  const searchCurrentValue = useSelector(getCurrenteSearch);
  const paginationAmount = useSelector(getPaginationAmount);
  const products = useSelector(getCurrentProducts);

  const renderContent = () => {
    if (searchCurrentValue) {
      const count = products.length ? paginationAmount : 0;

      return (
        <div className="total__wrapper result">
          <Text as="h5" className="mr-6">
            There are <Text as="span">{count}</Text> results for "{searchCurrentValue}"
          </Text>
        </div>
      );
    }

    return (
      <div className="total__wrapper">
        <Text as="h5" className="mr-6">
          There are <Text as="span">{totalAmount}</Text> illustrations now, all of them are free!
        </Text>
      </div>
    );
  };

  return renderContent();
};

export default TotalProduct;
