import React, { useState, useRef } from "react";
import "./donate.scss";

import { Text, Icon, Input } from "@ui-kit";
import ChevronIcon from "@ui-kit/icons/chevron-accent700.svg";

const Donate = ({ donate = 0, setDonate = () => {}, setConfirmData = ()=>{} }) => {
  const lineRef = useRef();
  const [selectedAmounts, setSelectedAmounts] = useState([]);
  const [showInput, setShowInput] = useState(false);
  const amounts = [5, 10, 20, 50];

  const handleClick = (amount, index) => {
    setSelectedAmounts(amounts.slice(0, index + 1));

    const persent = amount <= 5 ? 0 : amount * 2;
    lineRef.current.style = `width:${persent}%;`;

    setDonate(+amounts[index]);
    setConfirmData(false);
  };

  const setDonateHandle = (event) => {
    event.preventDefault();

    let value = parseInt(event?.target?.value);

    if (isNaN(value) || value < 0) value = 0;
    if (value > 1000000) value = 1000000;

    const filterAmount = amounts.filter(num => num <= value)
    const amount = Math.max(...filterAmount);
    const persent = amount <= 5 ? 0 : amount * 2;
    const index = amounts.indexOf(amount);

    lineRef.current.style = `width:${persent}%;`;

    handleClick(amount, index);

    setDonate(value);
    setConfirmData(false);
  };

  return (
    <div className="donate__container">
      <div className="donate-switcher__container">
        <div className="toggle-line background" />

        <div className="switcher-line">
          <div id="toggle-line" className="toggle-line toggle" ref={lineRef} />
        </div>

        {amounts.map((amount, index) => (
          <div key={amount} className="switcher-step">
            <div
              className={`donat-toggle ${
                selectedAmounts.includes(amount) ? "passed" : ""
              }`}
              onClick={() => handleClick(amount, index)}
            />

            <label className="donat-label">{amount}$</label>
          </div>
        ))}
      </div>

      <div className="donate__description" onClick={() => setShowInput(!showInput)}>
        <Text as="h5">
          If you want to support, you can leave a tip. Select any amount or{" "}
          <Text as="span" className="text link">
            enter your own
          </Text>
        </Text>
        <Icon src={ChevronIcon} className={`arrow__icon ${showInput ? 'down' : ''}`}/>
      </div>
      {showInput && (
        <div className="donate__input-container">
          <Input
            value={donate}
            placeholder="Enter your amount"
            type="text"
            onChange={setDonateHandle}
          />
        </div>
      )}
    </div>
  );
};

export default Donate;
