import React from 'react';
import './joinMovement.scss';
import { Text } from '@ui-kit';

const JoinMovement = () => {
  return (
    <div className="join__container">
      <div className="main__container join__content d-flex jc-center">
        <div className="join__wrapper">
          <Text as="h1">Join the Art4Free Movement!</Text>
          <Text as="h3">
            Together, we’re building a creative world based on mutual respect and collaboration
          </Text>
        </div>
      </div>
    </div>
  );
};

export default JoinMovement;
