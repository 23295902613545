import {
  LICENSES_URL,
  BROWSE_SEARCH_URL,
  //ADMIN_PROJECT_CREATE_URL,
  ABOUT_US_URL,
  BLOG_URL,
} from '@app/routes';

import { Link } from '@ui-kit';

export let MENU_LIST = [
  {
    isSearch: true,
  },
  {
    component: (
      <Link to={BROWSE_SEARCH_URL} theme="text">
        Gallery
      </Link>
    ),
  },
  {
    component: (
      <Link to={BLOG_URL} theme="text">
        Blog
      </Link>
    )
  },
  /*{
    component: (
      <Link to={ADMIN_PROJECT_CREATE_URL} theme="text">
        Order Illustrations
      </Link>
    ),
  },*/
  {
    component: (
      <Link to={LICENSES_URL} theme="text">
        Licenses
      </Link>
    ),
  },
  {
    component: (
      <Link to={ABOUT_US_URL} theme="text">
        About us
      </Link>
    ),
  },
];
