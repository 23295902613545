import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Carousel } from "antd";
import { BLOG_URL } from "@app/routes";

import "./similarBlogPosts.scss";

import { getBlogTC, setBlogAC, getBlog } from "@entities";
import { AvatarLabel } from '@features';
import { Text } from "@ui-kit";

const SimilarBlogPosts = () => {
  const dispatch = useDispatch();
  const blogPosts = useSelector(getBlog);
  const [slidesToShow, setSlidesToShow] = useState(1);

  const updateSlidesToShow = () => {
    const width = window.innerWidth;
    if (width < 606) {
      setSlidesToShow(1);
    } else if (width < 1000) {
      setSlidesToShow(2);
    } else {
      setSlidesToShow(3);
    }
  };

  useEffect(() => {
    dispatch(getBlogTC({page:1, limit: 5}));

    updateSlidesToShow();
    window.addEventListener("resize", updateSlidesToShow);

    return () => {
      dispatch(setBlogAC([]));
      window.removeEventListener("resize", updateSlidesToShow);
    };
    // eslint-disable-next-line
  }, []);

  const formattedDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleString('en-US', options);
}

  return (
    <div className="blog-posts__container">
      <div className="blog-posts__content">
        <div className="blog-posts__header">
          <Text as="h1">Other articles</Text>
        </div>
        <div className="blog-posts__items">
          <Carousel
            autoplay
            slidesToShow={slidesToShow}
            arrows
            //infinite={true}
            draggable={true}
          >
            {blogPosts.map((item, index) => {
              return (
                <div className="blog-posts__item" key={`item-${index}`}>
                  <Text as="h5" className="date mb-10">
                    {formattedDate(item.createdAt)}
                  </Text>
                  <Text as="h5" className="title mb-6">
                    {
                      <a href={`${BLOG_URL.pathname}/${item.id}`}>
                        {item.title}
                      </a>
                    }
                  </Text>
                  <div className="user-info">
                    <AvatarLabel 
                      author={item?.user} 
                      ava={item?.user?.ava} 
                      text={item?.user?.text}
                      size="xl"
                    />
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default SimilarBlogPosts;
