import React from 'react';
import './horizontalTabsDate.scss';
import { Button } from "@ui-kit";

const HorizontalTabsDate = ({period = 'month', setPeriod = ()=>{}}) => {

    return (
        <div className="horizontal-buttons">
        <Button
          size="xs"
          theme="secondary-gray"
          className={period === 'day' ? '' : 'unactive'}
          onClick={() => setPeriod('day')}
        >
          Day
        </Button>
        <Button 
          size="xs" 
          theme="secondary-gray" 
          className={period === 'month' ? '' : 'unactive'}
          onClick={() => setPeriod('month')}
        >
          Month
        </Button>
        <Button
          size="xs"
          theme="secondary-gray"
          className={period === 'year' ? '' : 'unactive'}
          onClick={() => setPeriod('year')}
        >
          Year
        </Button>
        <Button
          size="xs"
          theme="secondary-gray"
          className={period === 'total' ? '' : 'unactive'}
          onClick={() => setPeriod('total')}
        >
          All time
        </Button>
      </div>
    )
};

export default HorizontalTabsDate;