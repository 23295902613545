import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './payment-failed.scss';

import { setPaymentsStatusAC } from '@entities';
import { Text, Link } from '@ui-kit';
import {
    BASE_URL,
    ADMIN_PROJECT_CREATE_URL,
    BROWSE_SEARCH_URL,
    LICENSES_URL,
    CART_URL,
  } from '@app/routes';
import Arrow from '@ui-kit/icons/arrow-gray700.svg';

const PaymentFailed = ({ status = '', projectId = '' }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPaymentsStatusAC(''));
        // eslint-disable-next-line
    }, []);

    const goToPrevUrl = (event) => {
        event.preventDefault();
        dispatch(setPaymentsStatusAC(''));

        if(projectId) {
          return navigate({pathname: `/checkout/projects/${projectId}`});
        }
        navigate(CART_URL);
    };

    const renderStatus = () => {
      if (status === 'false_server') {
          return (
              <>
                  <Text as="h3" className="server">
                      Unfortunately, we are unable to process the payment at the moment due to 
                      technical issues. Please try again later. Thank you for your understanding.
                  </Text>
              </>
          )
      }

      return (
          <>
              <Text as="h3" className="mb-12">
                  We experienced a payment processing issue. 
              </Text>
              <Text as="h3" className="mb-8">
                  Here are some of the most common reasons why a payment may be declined:
              </Text>
              <ul className="mb-12">
                <li>
                  <Text as="h3">
                    Incorrect card information (please check CVV, card number, postal code, etc.).
                  </Text>
                </li>
                <li>
                  <Text as="h3">
                    Insufficient funds (balance, credit limit or transaction amount limit available 
                    on the card has been exceeded).
                  </Text>
                </li>
                <li>
                  <Text as="h3">
                    Card has expired.
                  </Text>
                </li>
                <li>
                  <Text as="h3">
                    Card does not support the specified currency.
                  </Text>
                </li>
                <li>
                  <Text as="h3">
                    Card was declined for an unknown reason.
                  </Text>
                </li>
              </ul>
              <Text as="h3" className="content">
                Please check your information, try again, or use an alternative method. Contact 
                support if the problem persists.
              </Text>
          </>
      )
    };

    return (
        <div className="payment-status__container">
          <Text as="h5" className="sub__title mb-12">
            Payment failed
          </Text>
          <Text as="h1" className="title mb-24">
            Payment processing issue
          </Text>
          <div className="payment-status__content-failed">
            {renderStatus()}
          </div>

          <div className="payment-status__shortlinks">
            <Link
              icon={Arrow}
              to={BASE_URL.pathname}
              onClick={goToPrevUrl}
              theme="secondary-gray"
              className="payment-status__goback"
            >
              Try again
            </Link>
          </div>

          <div className="payment-status__links">
            <div className="payment-status__links-item">
              <Link
                to={ADMIN_PROJECT_CREATE_URL.pathname}
                theme="text"
                className="arrow-right"
                size="xxl"
              >
                {' '}
                Order illustrations
              </Link>
              <Text as="h5">
                Leave your brief and create the illustration with the artist.
              </Text>
            </div>

            <div className="payment-status__links-item">
              <Link
                to={BROWSE_SEARCH_URL.pathname}
                theme="text"
                className="arrow-right"
                size="xxl"
              >
                {' '}
                Gallery
              </Link>
              <Text as="h5">
                Check out the high-quality Art4Free illustrations.
              </Text>
            </div>

            <div className="payment-status__links-item">
              <Link
                to={LICENSES_URL.pathname}
                theme="text"
                className="arrow-right"
                size="xxl"
              >
                {' '}
                License
              </Link>
              <Text as="h5">
                Find out everything you need to know about our licenses.
              </Text>
            </div>
          </div>
        </div>
    )
}

export default PaymentFailed;