import {
  SET_LOADING,
  SET_MOUNTHLY_AMOUNT,
  SET_PRODUCTS,
  SET_HOME_GALLERY,
  SET_ENTIRE_AMOUNT,
  SET_FREE_AMOUNT,
  SET_PAGINATION_FREE_AMOUNT,
  SET_PUBLISHED_ENTIRE_AMOUNT,
  SET_PAGINATION_AMOUNT,
  SET_MS_CONNECTED,
  DELETE_PRODUCT,
  DELETE_CHECKED_PRODUCTS,
  SET_DASHBOARD_TOTALS,
  SET_DASHBOARD_MOST,
  SET_DASHBOARD_GRAPH,
  SET_SALES_PRODUCTS,
} from './products.constants';

import { SET_CART_PRODUCTS } from '@entities/user/model/user.constants';

const initialState = {
  isLoading: true,
  products: [],
  homeGallery: {top:[],bottom:[]},
  salesProducts: [],
  paidProducts: [],
  entireAmount: 0,
  freeAmount: 0,
  paginationFreeAmount: 0,
  publishedEntireAmount: 0,
  monthlyAmount: 0,
  paginationAmount: 1,
  msConnected: false,
  dashboardTotals: {},
  dashboardMost: {},
  dashboardGraph: [],
};

const productsReducer = (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case SET_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    case SET_PRODUCTS: {
      return {
        ...state,
        products: payload,
      };
    }

    case SET_HOME_GALLERY: {
      return {
        ...state,
        homeGallery: payload,
      };
    }

    case SET_CART_PRODUCTS: {
      return {
        ...state,
        products: state.products.map((item) => {
          const isPresent = payload.cart.find(
            (product) => product.id === item.id
          );

          if (isPresent) {
            item.inCart = payload.flag;
          }

          return item;
        }),
      };
    }

    case SET_MOUNTHLY_AMOUNT: {
      return {
        ...state,
        monthlyAmount: payload,
      };
    }

    case SET_ENTIRE_AMOUNT: {
      return {
        ...state,
        entireAmount: payload,
      };
    }

    case SET_FREE_AMOUNT: {
      return {
        ...state,
        freeAmount: payload,
      };
    }

    case SET_PAGINATION_FREE_AMOUNT: {
      return {
        ...state,
        paginationFreeAmount: payload,
      };
    }

    case SET_PUBLISHED_ENTIRE_AMOUNT: {
      return {
        ...state,
        publishedEntireAmount: payload,
      };
    }

    case SET_PAGINATION_AMOUNT: {
      return {
        ...state,
        paginationAmount: payload,
      };
    }

    case SET_DASHBOARD_TOTALS: {
      return {
        ...state,
        dashboardTotals: payload,
      };
    }

    case SET_DASHBOARD_MOST: {
      return {
        ...state,
        dashboardMost: payload,
      };
    }

    case SET_DASHBOARD_GRAPH: {
      return {
        ...state,
        dashboardGraph: payload,
      };
    }

    case SET_SALES_PRODUCTS: {
      return {
        ...state,
        salesProducts: payload,
      };
    }

    case SET_MS_CONNECTED: {
      return {
        ...state,
        msConnected: payload,
      };
    }

    case DELETE_PRODUCT: {
      return {
        ...state,
        products: [...state.products.filter((p) => p.id !== payload)],
      };
    }

    case DELETE_CHECKED_PRODUCTS:
      return {
        ...state,
        products: [
          ...state.products.reduce( (acc, item) => {
            if (!payload.includes(item.id)) acc.push(item); 
            return acc;
          }, []),
        ],
      };

    default:
      return state;
  }
};

export default productsReducer;
