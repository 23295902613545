import React from "react";
import { useLocation } from 'react-router-dom';
import './products-menu.scss';

import { Button, Link } from '@ui-kit';
import {
    ADMIN_DASHBOARD_URL,
    ADMIN_MY_SALES_URL,
    ADMIN_PRODUCTS_URL,
    ADMIN_PROMOTED_URL,
    ADMIN_FREE_PRODUCTS_URL,
  } from '@app/routes';

const ProductsMenu = ({ onSubmitProducts, isMsConnected }) => {
    const location = useLocation();

    const isActiveButton = (url) => {
        const path = url.pathname;
    
        if (path === location.pathname) {
          return 'active';
        }
    
        return '';
      };

    return (
        <div className="products__menu">
            <Button 
              size="lg"
              onClick={onSubmitProducts}
              isDisabled={!isMsConnected}
              className="mb-24"
            >
              {!isMsConnected ? 'Processing...' : 'Start processing'}
            </Button>
            <div className="menu__pages">
                <Link
                    to={ADMIN_DASHBOARD_URL}
                    className={`${isActiveButton(ADMIN_DASHBOARD_URL)}`}
                    theme="link-gray"
                >
                    Dashboard
                </Link>
                <Link
                    to={ADMIN_MY_SALES_URL}
                    className={`${isActiveButton(ADMIN_MY_SALES_URL)}`}
                    theme="link-gray"
                >
                    My sales
                </Link>
                <Link
                    to={ADMIN_PRODUCTS_URL}
                    className={`${isActiveButton(ADMIN_PRODUCTS_URL)}`}
                    theme="link-gray"
                >
                    My products
                </Link>
                <Link
                    to={ADMIN_PROMOTED_URL}
                    className={`${isActiveButton(ADMIN_PROMOTED_URL)}`}
                    theme="link-gray"
                >
                    Promoted
                </Link>
                <Link
                    to={ADMIN_FREE_PRODUCTS_URL}
                    className={`${isActiveButton(ADMIN_FREE_PRODUCTS_URL)}`}
                    theme="link-gray"
                >
                    Free
                </Link>
            </div>
        </div>
    )
};

export default ProductsMenu;