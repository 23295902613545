import React, {useEffect, useRef} from 'react';
import './copyright.scss';

import {setVisibleSubFooterAC} from '@entities'
import { Link } from 'react-router-dom';
import { Text, Icon } from '@ui-kit';
import { useOnScreen } from '@shared';
//import Instagram from '@ui-kit/icons/social/instagram-gray400.svg';
import Dribbble from '@ui-kit/icons/social/dribbble-gray400.svg';
import Behance from '@ui-kit/icons/social/behance-gray400.svg';
import { useDispatch } from 'react-redux';


const Copyright = () => {
    const ref = useRef();
    const dispatch = useDispatch();
    const isVisibleSubFooter = useOnScreen(ref);

    const currentYear = new Date().getFullYear();
    
    useEffect(() => {
        dispatch(setVisibleSubFooterAC(isVisibleSubFooter));
    // eslint-disable-next-line
    },[isVisibleSubFooter])

    return (
        <div className="sub-footer__container d-flex" ref={ref}>
            <Icon src="/images/main_logo-w.png" className="sub-footer__logo"/>
            <div className="sub-footer__content d-flex">
                <Text as="span" className="sub-footer__text">
                    {`© 2021-${currentYear} Art4Free | All Rights Reserved`}
                </Text>
                <div className="sub-footer__social d-flex">
                    {/*<Link to={"https://instagram.com/"} target="_blank">
                        <Icon src={Instagram} />
                    </Link>*/}
                    <Link to={"https://www.behance.net/briefstock"} target="_blank">
                        <Icon src={Behance} />
                    </Link>
                    <Link to={"https://dribbble.com/myteamart"} target="_blank">
                        <Icon src={Dribbble} />
                    </Link>
                </div>
            </div>
        </div>
    )
};

export default Copyright;
