import { replaceTo } from '@lib/utils';

export class Product {
    amount_downloaded;
    amount_likes;
    ava;
    basketId;
    compatibility_draw;
    correctHeight;
    correctWidth;
    firstName;
    html_description;
    id;
    illustration_formats;
    key_words;
    lastName;
    linkId;
    name;
    pathToBrowse;
    pathToPreview;
    price;
    isFree;
    rate_type;
    sale;
    spending_time;
    title;
    width;
    user = {};
  
    constructor(option) {
        this.amount_downloaded = option.amount_downloaded;
        this.amount_likes = option.amount_likes;
        this.correctHeight = option.correctHeight;
        this.correctWidth = option.correctWidth;
        this.html_description = option.html_description;
        this.name = option.name;
        this.pathToPreview = option.pathToPreview;
        this.price = option.price;
        this.isFree = +option.isFree;
        this.rate_type = option.rate_type;
        this.sale = option.sale;
        this.spending_time = option.spending_time;
        this.title = option.title;
        this.width = option.width;
        this.id = option.id;
        this.price = option.price;
        this.linkId = option.linkId;
        this.pathToBrowse = option.pathToBrowse;
        try {
            const clearKeyWords = replaceTo(option.key_words, ['&quot;','[\\[\\]" ]',';',]);
            this.key_words = option.key_words ? clearKeyWords.split(',') : [];
        } catch {
            return console.error("BAD key_words", this.id, option.key_words);
        }
        try {
            const clearCompatibility = replaceTo(option.compatibility_draw,'&quot;','"');
            this.compatibility_draw = option.compatibility_draw ? JSON.parse(clearCompatibility) : [];
        } catch {
            return console.error("BAD compatibility_draw", this.id, option.compatibility_draw);
        };
        try {
            const formats = option.illustration_formats.replaceAll('&quot;', '"');
            this.illustration_formats = formats ? JSON.parse(formats) : [];
        } catch (e) {
            return console.error("BAD illustration_formats", this.id, option.illustration_formats);
        }
        this.user = {
            //email: option?.email,
            id: option.basketId || 2,
            firstName: option?.firstName || 'Art4Free',
            lastName: option?.lastName || '',
            ava: option?.ava || `2_avatar.jpg`,
        };
    }
}
