import React, { useEffect, useRef, useState } from 'react';
import { Popover } from 'antd';
import './hoverProductCard.scss';

import { Text, Tooltip, Button } from '@ui-kit';
import { AvatarLabel } from '@features';
import { LICENSES_URL } from '@app/routes';
import DownloadIcon from '@ui-kit/icons/download-gray700.svg';


const HoverProductCard = ({ 
  title = '',
  author = '',
  getFree = () => {},
}) => {
  const [ widthCard , setWidthCard ] = useState('');
  const [ license, setLicense ] = useState('Extended commercial license');
  const href = useRef();
  let currentTitle = title;

  useEffect(() => {
    if(widthCard) {
      setResizer();
    }
    // eslint-disable-next-line
  }, [widthCard]);

  const setResizer = () => {
    const MAX_RESIZE_WIDTH = 320;
    const CRITICAL_WIDTH = 215;

    const current = href.current.parentNode;
    const parent = current.parentNode;
    const { width } = parent.getBoundingClientRect();
    setWidthCard(width);

    if (width > MAX_RESIZE_WIDTH) {
      current.classList.remove('resizer', 'cut-text');
      setLicense('Extended commercial license');
      currentTitle = title;
    }

    if (width > CRITICAL_WIDTH && width < MAX_RESIZE_WIDTH) {
      current.classList.add('resizer');
      current.classList.remove('cut-text');
      setLicense('Extended commercial');
      currentTitle = title;
    }

    if (width < CRITICAL_WIDTH) {
      current.classList.add('resizer', 'cut-text');
      currentTitle = '';
    }
  };

  const onLinkClick = (e) => {
    e.stopPropagation();
    window.open(`${LICENSES_URL.pathname}#rights`, '_blank');
    e.stopPropagation();
  }

  const onOver = () => {
    href.current.style.display = 'flex';
    setResizer();
  }

  const onLeave = () => {
    href.current.style.display = 'none';
  }

  const renderDownloadIcon = () => {

    return (
      <Tooltip
        position="top"
        direction="revers"
        ancorPosition="anchor-right-bottom"
        content={
          <>
            <Text as="h6" className="hoverbackground__tooltip">
              Download for $0
            </Text>
            <Text as="h6" className="hoverbackground__learn-more">

            </Text>
          </>
        }
      >
        <Button
          size="lg"
          iconLeft={DownloadIcon}
          onClick={getFree}
          className="download__button download__icon"
        />
      </Tooltip>
    )
  };

  const content = (
    <Text as="h5">{title || ''}</Text>
  );

  return (
    <div 
      onMouseOver={onOver}
      onMouseLeave={onLeave}
      className="hoverbackground__container" 
    >
      {author && (
        <div
          ref={href}
          key={`author-${author.id}`}
          className="d-flex jc-sb parent__info"
        >
          <Popover
            overlayStyle={{overflow: 'initial'}}
            overlayClassName="product-title_pop"
            content={content}
            trigger={'hover'}
            arrow={true}
            placement={'topLeft'}
          >
            <>
              <Text as="h5" className="product-title">{currentTitle}</Text>
            </>
          </Popover>
          <div className="d-flex jc-sb ai-end parent__hover">
            <div className="hoverbackground__info">
            <AvatarLabel author={author} className="mb-8" />
            <Tooltip
              content={
                <>
                  <Text as="h6" className="hoverbackground__tooltip">
                    License for any purpose
                  </Text>
                  <Text as="h6" className="hoverbackground__learn-more">
                    Learn more about license options
                  </Text>
                </>
              }
            >
              <Button
                theme="link-gray"
                size="sm"
                className="license"
                onClick={onLinkClick}
              >
                {license}
              </Button>
            </Tooltip>
            </div>
            {renderDownloadIcon()}
          </div>
        </div>
      )}
    </div>
  );
};

export default HoverProductCard;
