import React from "react";
import "./total-chart.scss";

import { Text } from "@ui-kit";

const TotalChart = ({ items = {} }) => {
  return (
    <div className="total-chart__container">
      <div className="total__item">
        <Text as="h6">Total earned</Text>
        <Text as="h2">{items?.earned}</Text>
      </div>
      <div className="total__item">
        <Text as="h6">Total downloads</Text>
        <Text as="h2">{items?.downloaded}</Text>
      </div>
      <div className="total__item">
        <Text as="h6">Total views</Text>
        <Text as="h2">{items?.views}</Text>
      </div>
      <div className="total__item">
        <Text as="h6">Total tips</Text>
        <Text as="h2">{items?.tips}</Text>
      </div>
    </div>
  );
};

export default TotalChart;
