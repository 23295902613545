const { REACT_APP_WS_URL } = process.env;

export class Socket {
  userId;
  id;
  roomId;
  socket;
  newConnection;
  socketReconnectInterval;
  reconnectAttemptsCount;
  maxReconnectAttemptsCount;
  listeners;
  lastPingReceivedAt;
  connected;
  onOpenCallback;
  onErrorCallback;
  onCloseCallback;

  constructor(userId, role) {
    if (typeof Socket.instance === "object") {
      return Socket.instance;
    }

    this.url = REACT_APP_WS_URL;
    this.userId = userId;
    this.roomId = '';
    this.id = +new Date();
    this.listeners = [];
    this.lastPingReceivedAt = null;
    this.connected = false;
    this.reconnectAttemptsCount = 0;
    this.maxReconnectAttemptsCount = 5;
    this.socketReconnectInterval = null;
    this.newConnection = true;

    Socket.instance = this;

    return this;
  }

  send(event, data = {}) {
    //console.log(`Socket send event`, event, data)
    try {
      this.socket.send(JSON.stringify({ event, data }));
    } catch (error) {
      console.warn(`[WSS] id=${this.id} Cannot send message, socket is not connected.`, error);
      this.connected = false;
      this.reconnect();
    }
  }

  listen(callback) {
    const listenersMap = this.listeners.reduce((map, lsr) => {
      map.set(lsr.toString(), lsr);

      return map;
    }, new Map());

    listenersMap.set(callback.toString(), callback);
    this.listeners = Array.from(listenersMap.values());
    this.socket.onmessage = (event) => {
      this.listeners.forEach((lsr) => lsr(event));
    };
  }

  stopListening(callback) {
    this.listeners = this.listeners.filter((lsr) => lsr !== callback);
  }

  initListeners(onOpen, onError, onClose) {
    this.onOpenCallback = onOpen;
    this.onErrorCallback = onError;
    this.onCloseCallback = onClose;
    this.roomId = window.roomId;
    const accessToken = localStorage.getItem('token');

    this.socket = new WebSocket(
      `${REACT_APP_WS_URL}/connector?userId=${this.userId}&id=${this.id}&token=${accessToken}`
    );

    this.socket.onopen = () => {
      this.listen((event) => this.handlePingRequest(event));
      console.log(`[WSS] id=${this.id} connecting...`);

      onOpen();
      
      this.sendTestMessage();
    };

    this.socket.onclose = (e) => {
      console.log(`[WSS] id=${this.id} Socket is closed`);

      if (onClose) onClose(e);

      if (this.connected) {
        this.connected = false;
        this.shouldReconnect() && this.reconnect();
      } else {
        return;
      }
    };

    this.socket.onerror = (e) => {
      console.log(`[WSS] id=${this.id} Socket error. Reconnect will be attempted in 2 second.`, e);

      this.connected = false;
      if (onError) onError(e);

      this.shouldReconnect() && this.reconnect();
    };
  }

  shouldReconnect() {
    return !this.connected && this.reconnectAttemptsCount < this.maxReconnectAttemptsCount;
  }

  reconnect() {
    this.reconnectAttemptsCount++;
    if (this.reconnectAttemptsCount >= this.maxReconnectAttemptsCount) {
      //console.log(`[WSS] id=${this.id} Socket MAX reconnect!`);
      clearInterval(window.socketReconnectInterval);

      if (this.onReconnectError) this.onReconnectError();
      this.close()
      return;
    }

    //console.log(`[WSS] id=${this.id} Socket is reconnect num:`, this.reconnectAttemptsCount);
    setTimeout(() => {
      this.initListeners(this.onOpenCallback, this.onErrorCallback, this.onCloseCallback);
    }, 2000);
  }

  handlePingRequest(event) {
    const data = event?.data;
    if (!data) {
      return;
    }

    const toJson = JSON.parse(data);
    const customEvent = toJson.event;
    //console.log(`Socket get event`, customEvent)
    this.connected = true;

    if (customEvent === "ping") {
      const now = new Date();
      const time = [
          now.getHours().toString().padStart(2, '0'),
          now.getMinutes().toString().padStart(2, '0'),
          now.getSeconds().toString().padStart(2, '0')
      ].join(':');
      console.log(`[WSS] id=${this.id} start ping-pong connection`, time);

      this.lastPingReceivedAt = new Date();
      this.reconnectAttemptsCount = 0;
      this.send({ event: "pong" });
    }
  }

  sendTestMessage() {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      if (this.newConnection) {
        this.send({ event: "pong" });
        this.newConnection = false;
      } else {
        //console.log("[WSS] Message sent to reconnect");
        this.send("room.join", {roomId: this.roomId});
      }

      setTimeout(() => {
        if (!this.connected) {
          console.warn("[WSS] No response received, connection might be down");
        }
      }, 5000);
    }
  }

  isConnected() {
    return this.connected;
  }

  close() {
    //console.log("[WSS] Socket connection closed manually")
    this.connected = false;
    this.socket.close();
  }

  onReconnectError() {}
}
