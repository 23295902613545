import React, {useState, useRef} from 'react';
import './profile-purchases.scss';

import { Avatar, Icon, Text, Button, Tooltip, Badge } from '@ui-kit';
import { geShortCut } from '@shared';
import { WaitingPreloader } from '@widgets';
import { BROWSE_URL } from '@app/routes';
import DownloadIcon from '@ui-kit/icons/download-white.svg';

const PurchasedItem = ({ item = {}, makeRequest,  }) => {
  const preloaderKeyRef = useRef();
  const [isDownload, setIsDownload] = useState(false);

  const { 
    pathToBrowse = '', 
    title = '', 
    user = {}, 
    id = 0, 
    name = '', 
    paidAt = '',
    price = ' ',
    productId = 0,
  } = item;
  const {
    firstName = 'Art4Free', 
    lastName, 
    ava = '2.jpg' 
  } = user;
  const date = paidAt ? new Date(paidAt).toLocaleString(undefined, {
    year: 'numeric', 
    month: '2-digit', 
    day: '2-digit'
  }) : '-';
  const PRODUCT_URL = process.env.REACT_APP_PRODUCTS_URL;

  let linkId = title.toLowerCase().replace(/[.,%]/g, '')
  linkId = linkId.replace(/ /g, '-');
  const isFree = price === 0;

  const download = async () => {
    setIsDownload(true);
    preloaderKeyRef.current = 'downloadArchive';
    const product =  await makeRequest({ id, name });
    setIsDownload(false);
    return product;
  };

  const infoTooltip = (
    <div className="product__tooltip">
      <div className="product__tooltip-line">
        <Text as="h5">Illustrator</Text>
        <Text as="h5" className="tooltip-name">
          {`${firstName || ''} ${lastName || ''}`}
        </Text>
      </div>
      <div className="product__tooltip-line">
        <Text as="h5">Price</Text>
        <Text as="h5" className="tooltip-name">
          {!isFree ? `$${price}` : "Free"}
        </Text>
      </div>
      <div className="product__tooltip-line">
        <Text as="h5">Date</Text>
        <Text as="h5" className="tooltip-name">
          {date || ''}
        </Text>
      </div>
      
    </div>
  );

  return (
    <div
      className="admin__table-row"
    >
      <div className="product-name d-flex ai-center">
        <div className="product-name__icon__wrapper pr">
          <a
            className="product__info-link"
            href={`${BROWSE_URL.pathname}/${linkId}-${productId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon src={`${PRODUCT_URL}/${pathToBrowse}`} />
          </a>
        </div>

        <div className="product-title">
            <Text as="h5">{title || ''}</Text>
            <Tooltip
              content={infoTooltip}
              position="top"
              ancorPosition="anchor-right-middle"
            >
              <Text as="span" className="product-info">More info</Text>
            </Tooltip>
            
        </div>
      </div>

        <div className="d-flex ai-center product-illustrator">
            <div className="illustrator-avatar__wrapper">
            <Avatar 
                size="sm"
                src={ava} 
                text ={geShortCut(firstName, lastName)}
            />
            </div>
            <div className="illustrator-name">
              <Text as="h5">
                    {`${firstName || ''} ${lastName || ''}`}
                </Text>
            </div>
        </div>

        <div className="product-price">
          {!isFree ? <Text as="h6">${price}</Text> : <Badge size="sm" theme="green" text="Free"/>}
        </div>

        <div className="product-date">
            <Text as="h6">{date || ''}</Text>
        </div>
        
        {isDownload && <WaitingPreloader keyText={preloaderKeyRef.current} />}
        <div className="product-download">
            <Button 
              size="sm" 
              iconLeft={DownloadIcon}
              onClick={download}
              isDisabled={isDownload}
            >
              <Text as="span">Download</Text>
            </Button>
        </div>
    </div>
  );
};

export default PurchasedItem;
