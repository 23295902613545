import React, { useState, useEffect, forwardRef } from 'react';
import { Carousel } from "antd";
import './options.scss';

import { Text } from '@ui-kit';
import Free from './free';
import Editing from './editing';
import Custom from './custom';

const Options = forwardRef((props, ref) => {
  const [slidesToShow, setSlidesToShow] = useState(3);
  const [isViewArrows, setIsViewArrows] = useState(false);

  const updateSlidesShow = () => {
    const width = window.innerWidth;
    if (width < 881) {
      setSlidesToShow(1);
      setIsViewArrows(true);
    } else if (width < 1376) {
      setSlidesToShow(2);
      setIsViewArrows(true);
    } else {
      setSlidesToShow(3);
      setIsViewArrows(false);
    }
  };

  useEffect(() => {
      const track = document.querySelector('.carousel')
      if (track) {
        if(slidesToShow === 3) {
          return track.classList.add('default');
        }

        track.classList.remove('default');
      }
  }, [slidesToShow])

  useEffect(() => {
    updateSlidesShow();
    window.addEventListener('resize', updateSlidesShow);

    return () => {
      window.removeEventListener('resize', updateSlidesShow);
    };
  }, []);

  return (
    <div className="options__container">
      <div className="main__container options__content">
        <div className="options__header">
          <Text as="h1">
            Art4Free Options
          </Text>
        </div>
        <div className="options__items">
          <Carousel
            className="carousel"
            //autoplay
            slidesToShow={slidesToShow}
            arrows={isViewArrows}
            infinite={false}
            draggable={isViewArrows}
            dots={isViewArrows}
          >
            <Free />
            <Editing />
            <Custom />
          </Carousel>
        </div>
      </div>
    </div>
  );
});

export default Options;
