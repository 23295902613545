import {
  SET_SETTINGS,
  SET_SETTINGS_LOADING,
  SET_BLOG,
  SET_BLOG_ITEM,
  SET_PAGINATION_AMOUNT,
} from "./settings.constants";

const initialState = {
  hourlyRate: 0,
  hourlyWithoutLicense: 0,
  hoursNumber: 0, //???
  clientsCount: 0,
  ordersCount: 0,
  ordersProgressCount: 0,
  clientsCountAll: 0,
  ordersCountAll: 0,
  ordersProgressCountAll: 0,
  isLoading: true,
  paginationAmount: 0,
  blog: [],
  blogPost: {},
};

const userReducer = (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case SET_SETTINGS: {
      return {
        ...state,
        ...payload,
      };
    }

    case SET_SETTINGS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    case SET_BLOG: {
      return {
        ...state,
        blog: payload,
      };
    }

    case SET_BLOG_ITEM: {
      return {
        ...state,
        blogPost: payload,
      };
    }

    case SET_PAGINATION_AMOUNT: {
      return {
        ...state,
        paginationAmount: payload,
      };
    }

    default:
      return state;
  }
};

export default userReducer;
