import React from 'react';
import './stepper.scss';

import { Tooltip, Text } from '@ui-kit';
import StepMilestone from './stepMilestone';
import StepDuration from './stepDuration';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';

const Stepper = ({
  followingId = 0,
  cost = 0,
  workingHoursSum = '',
  currentStepsHours = [],
  followingData = [],
  unpaidHours
}) => {
  const mapWithFollowingData = STEPPER_STATE.map((item, index) => {
    if (!followingData.length) {
      return null;
    }

    item.date = followingData[index].date;

    return item;
  });

  const checkDate = (date) => {
    if (!date) return;

    return (
      <Text as="h6" className="time_milestone">
        ( {format(date, 'dd LLLL', { locale: enUS })} )
      </Text>
    );
  };

  const renderHorseMinutes = (item, key, index) => {
    const hours = item[key].hours;
    const minutes = item[key].minutes;
    const date = item[key].date;

    if (!hours && !minutes) return;

    if (!hours && minutes) {
      return (
        <div className="content_milestone" key={`hours_milestone-${index}`}>
          <Text as="h6" className="hours_milestone">
            {minutes} minutes
          </Text>
          {checkDate(date)}
        </div>
      );
    }

    if (hours && !minutes) {
      return (
        <div className="content_milestone" key={`hours_milestone-${index}`}>
          <Text as="h6" className="hours_milestone">
            {hours} hour
          </Text>
          {checkDate(date)}
        </div>
      );
    }

    return (
      <div className="content_milestone" key={`hours_milestone-${index}`}>
        <Text as="h6" className="hours_milestone">
          {hours} hour {minutes} minutes
        </Text>
        {checkDate(date)}
      </div>
    );
  };

  const getTimePartition = () => {
    const hours = +workingHoursSum.split('.')[0];
    const minute = +workingHoursSum.split('.')[1];

    if (!hours && !minute) {
      return 0;
    }

    if (hours && !minute) {
      return `${hours} hours`;
    }

    if (!hours && minute) {
      return `${minute} minutes`;
    }

    return `${hours} hours ${minute} minutes`;
  };

  const getTimeUnpaid = () => {
    const unpaidModul = Math.abs(unpaidHours)
    const status = unpaidHours > 0 ? 'Unpaid' : 'Overpayment';
    const hours = Math.floor(unpaidModul);
    const minute = (unpaidModul % 1) * 60;

    if (!hours && !minute) {
      return 'Fully paid';
    }

    if (hours && !minute) {
      return `${status}: ${hours} hours`;
    }

    if (!hours && minute) {
      return `${status}: ${minute} minutes`;
    }

    return `${status}: ${hours} hours ${minute} minutes`;
  }

  const renderStep = (index, { type, title, date, status, key }) => {
    const extreme = index === 0 || index === STEPPER_STATE.length - 1;

    const currentHours = currentStepsHours.filter((item) => item[key]);

    const renderTooltipeContent = () => {
      if (key === 1) {
        return (
          <div className="">
            <Text as="h6" className="milestone_tooltip__title">
              Art4Free clients do not need <br /> to pay for this step
            </Text>
          </div>
        );
      }

      if (key === 7) {
        return (
          <div className="">
            <Text as="h6" className="milestone_tooltip__title">
              The total cost of the project:
            </Text>
            <Text as="h6" className="cost_milestone">
              {cost}$
            </Text>
            <Text as="h6" className="cost_milestone__descr">
              Thank you for entrusting your <br />
              project to the Art4Free team.
              <br />
              We are looking forward <br />
              to collaborating with you again!
            </Text>
          </div>
        );
      }

      return (
        <>
          <Text as="h6" className="milestone_tooltip__title">
            Total spent {getTimePartition()}
          </Text>
          <Text as="h6" className="milestone_tooltip__title">
            {getTimeUnpaid()}
          </Text>
          <div>
            {currentHours.map((item, index) =>
              renderHorseMinutes(item, key, index)
            )}
          </div>
        </>
      );
    };

    return type === 'milestone' ? (
      <Tooltip
        position="top"
        content={renderTooltipeContent()}
        key={`stepper-${index}`}
      >
        <StepMilestone
          key={`stepper-${index}`}
          extreme={extreme}
          title={title}
          date={date}
          status={!!status}
        />
      </Tooltip>
    ) : (
      <StepDuration
        key={`stepper-${index}`}
        extreme={extreme}
        status={!!status}
      />
    );
  };

  STEPPER_STATE.forEach((step) => {
    step.status = step.key <= followingId ? 1 : 0;
  });

  return (
    <div className="steps__container">
      {mapWithFollowingData.map((item, index) => renderStep(index, item))}
    </div>
  );
};

const STEPPER_STATE = [
  { key: 1, title: 'Brief', type: 'milestone', date: null, status: 0 },
  { key: 2, type: 'duration', status: 0 },
  {
    key: 3,
    title: 'Sketching',
    type: 'milestone',
    date: null,
    status: 0,
  },
  { key: 4, type: 'duration', date: null, status: 0 },
  {
    key: 5,
    title: 'Illustrating',
    type: 'milestone',
    date: null,
    status: 0,
  },
  { key: 6, type: 'duration', date: null, status: 0 },
  { key: 7, type: 'duration', date: null, status: 0 },
  {
    key: 8,
    title: 'Project completed',
    type: 'milestone',
    date: null,
    status: 0,
  },
];

export default Stepper;
