import React, { useRef, useEffect, useState } from 'react';
import { map } from 'underscore';
import { useSelector } from 'react-redux';
import './productsGallery.scss';

import { Text } from '@ui-kit';
import { NoResult } from '@features';
import IllustrationCard from './illustrationCard';
import { getCurrenteSearch } from '@entities';

const PRODUCT_WIDTH = {min: 70};
const PRODUCT_HEIGHT = {min: 125, max: 175};
const DEFAULT_PRODUCT_HEIGHT = 250;
const MARGIN_DEFAULT = 8;

const ProductsGallery = ({ products, preloader }) => {
  const galleryContainer = useRef(null);
  const galleryListRef = useRef(null);
  const searchCurrentValue = useSelector(getCurrenteSearch);
  const [windowWidth, setWindowWidth] = useState(0);
  const [isSorting, setIsSorting] = useState(null);
  const [sortWidthProducts, setSortWidthProducts] = useState(products);

  const MAX_GALLERY_WIDTH =
    galleryContainer?.current?.getBoundingClientRect().width || 0;
  const MIN_PRODUCT_HEIGHT = MAX_GALLERY_WIDTH > 1225 ? 
    PRODUCT_HEIGHT.max : PRODUCT_HEIGHT.min;
  const SCREEN_WIDTH = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const isMobileScreen = SCREEN_WIDTH < 606;

  const galleryClasses = ['gallery__list'];

  const resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    if(windowWidth) {
      renderProducts();
    };
    // eslint-disable-next-line
  }, [windowWidth]);

  useEffect(() => {
    if(MAX_GALLERY_WIDTH && products.length) {
      return sortIndexProducts();
    }

    setSortWidthProducts({});
    // eslint-disable-next-line
  }, [products, MAX_GALLERY_WIDTH]);

  useEffect(() => {
    if(galleryListRef.current && MAX_GALLERY_WIDTH) {
      sortProducts();
    }
    // eslint-disable-next-line
  }, [sortWidthProducts])

  const sortIndexProducts = () => {
    let list = [...products];
    const list2 = [];
    
    while (list.length) {
      const firstItem = list.shift();
      let totalWidth = firstItem.correctWidth;
      list2.push(firstItem);

      let i = 0;
      while (true) {
        if (i > list.length - 1 ) break;

        const currentItem = list[i];
        
        if ((totalWidth + currentItem.correctWidth + MARGIN_DEFAULT) <= MAX_GALLERY_WIDTH) {
            totalWidth += currentItem.correctWidth + MARGIN_DEFAULT;
            list2.push(currentItem);
            list.splice(i, 1);
            i = 0;
        } else {
          totalWidth = 0;
          i = i+1;
        };

      }
    }
    
    setSortWidthProducts(list2);
  };

  const sortProducts = () => {
    setIsSorting(true)
    const galleryItems = Array.from(document.querySelectorAll('.product__item'));
    let dataRow = 1;
    let dataColumn = 1;
    let restMinWidth = MAX_GALLERY_WIDTH;
    const lineProdArr = [];
    let lineMinHeight = MIN_PRODUCT_HEIGHT;

    galleryItems.map((product, index) => {
      const nextIndex = index + 1;
      const isLastItem = nextIndex === galleryItems.length;

      product.setAttribute('data-row', dataRow);
      product.setAttribute('data-column', dataColumn);

      // Current product size
      const currentWidth = +product.getAttribute('data-width');
      const currentHeight = DEFAULT_PRODUCT_HEIGHT;
      let currentMinWidth = currentWidth / currentHeight * lineMinHeight;
      const productWidth = isMobileScreen ? 40 : PRODUCT_WIDTH.min
 
      if (currentMinWidth < productWidth) {
        lineMinHeight = currentHeight / currentWidth * productWidth;
        currentMinWidth = Math.max(productWidth, currentWidth / currentHeight * lineMinHeight);
        // recalc all minWidth in line
        lineProdArr.map((item) => {
          const currentItemMinWidth = Math.max(productWidth, item.width / item.height * lineMinHeight);
          item.minWidth = currentItemMinWidth;
          return item;
        })
      }

      lineProdArr.push({index: index, width: currentWidth, minWidth: currentMinWidth, height: lineMinHeight});

      // Next product size
      const nextWidth = galleryItems[nextIndex]?.getAttribute('data-width') || 0;
      const nextHeight = galleryItems[nextIndex]?.offsetHeight ? DEFAULT_PRODUCT_HEIGHT : 0;
      let nextMinWidth = nextWidth / nextHeight * lineMinHeight || 0;
      if (nextMinWidth) {
        nextMinWidth = Math.max(productWidth, nextMinWidth);
      }

      // Calc max quantity products in line
      const restMinProdWidth = restMinWidth - currentMinWidth;
      const sumLineProdWidth = MAX_GALLERY_WIDTH - restMinProdWidth


      //СЧИТАЕТ ПО МИНИМУМУ (53) а не 70!!!
      const marginSum = MARGIN_DEFAULT * lineProdArr.length - MARGIN_DEFAULT;
      const allMinWidth = sumLineProdWidth + marginSum;       
      const checkNextWidth = allMinWidth + nextMinWidth + MARGIN_DEFAULT;      

      if(checkNextWidth > MAX_GALLERY_WIDTH || isLastItem) {
        let sumWidth = 0;
        lineProdArr.map(item => sumWidth += +item.width);
        const percentScale = (MAX_GALLERY_WIDTH - marginSum) / sumWidth;

        lineProdArr.map((item, i) => {
          const isLineLastItem = (i + 1) === lineProdArr.length
          const image = galleryItems[item.index];
          const widthScale = item.width * percentScale;
          const number = widthScale * 2;
          const newImgWidth = Math.max(productWidth, Math.trunc(number) / 2);
 
          if(isLastItem && isLineLastItem && lineProdArr.length === 1) {
            image.style.cssText = ``;

            return item;
          }

          image.style.cssText = `width: ${newImgWidth}px; flex-grow:${newImgWidth};`;
          return item;
        });        
      
        ++dataRow;
        dataColumn = 1;
        restMinWidth = MAX_GALLERY_WIDTH;
        lineMinHeight = MIN_PRODUCT_HEIGHT;
        lineProdArr.length = 0;

        return product;
      }

      ++dataColumn;
      restMinWidth = restMinProdWidth;

      return product;
    });

    setIsSorting(false)
  };

  const renderProducts = () => {
    if (!sortWidthProducts.length && searchCurrentValue) {
      return (
        <div className="wrong_container d-flex jc-center">
          <NoResult>
            <Text as="h4" className="no-result__title mt-16">
              No matching illustrations
            </Text>
            <Text as="h5" className="no-result__text mt-4">
              Your search request "{searchCurrentValue}" doesn’t match any
              illustrations. <br />
              Please, change it or check the spelling.
            </Text>
          </NoResult>
        </div>
      );
    }

    return (
      <ul className={galleryClasses.join(' ')} ref={galleryListRef}>
        {map(sortWidthProducts, (item, index) => (
          <IllustrationCard
            key={`product-${item.id}`}
            product={item}
            index={index}
            preloader={preloader}
            isSorting={isSorting}
          />
        ))}
      </ul>
    );
  };

  return (
    <div className="gallery__container-page" ref={galleryContainer}>
      {renderProducts()}
    </div>
  );
};

export default ProductsGallery;
